export interface FieldOption { label: string, value: string, isMandatory?: boolean, type?: string }

export interface CustomerNote { message: string, user: string, date: string }

export interface SelectedField {
    field: string,
    filters:{
        match: string[],
        regExp: string[]
    }
}

export const connectionTypes = [
    {label: "Snapshot", value: "snapshot"},
    {label: "Subscribe", value: "subscribe"}
];

export interface UserInfo {
    email: string,
    display: string
}

export interface FieldItem {
    name: string,
    type: string
}

export interface CustomerInfo extends Object{
    permissions: string,
    role: string[],
    limit: number,
    used: number,
    notes: string,
    name: string,
    oid: string,
    isNew: boolean
}

export const Roles = {
    "NONE": "NONE",
    "USER": "FenicsMDInternalUser",
    "ADMIN": "FenicsMDAdmin",
    "CUSTOMER": "FenicsMDClient",
    "SYS_ADMIN": "FenicsMDSysAdmin",
};

export const RolesDescription = {
    "NONE": "NONE",
    "USER": "User",
    "ADMIN": "Admin",
    "CUSTOMER": "Customer",
    "SYS_ADMIN": "Sys Admin",
};

export interface AlertInfo {
    message: string,
    variant: string,
    title: string,
    show: boolean
}


export interface ResourceAccess {
    [key: string]: {
        roles: string[]
    }
}

export interface ValidateField extends Object{
    style: any,
    event: number
}

export interface ValidateFilters extends Object{
    className: string,
    event: number
}