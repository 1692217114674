import React, {CSSProperties, FunctionComponent, useEffect, useRef, useState} from 'react';
import {Button, CloseButton, Col, FormCheck, FormControl, InputGroup, Row} from "react-bootstrap";
import makeAnimated from 'react-select/animated';
import Select, {ClearIndicatorProps, components, MultiValueGenericProps, OptionProps, SingleValue} from "react-select";
import {MultiValue} from "react-select/dist/declarations/src/types";
import {
    AlertInfo,
    connectionTypes,
    CustomerInfo,
    FieldItem,
    FieldOption,
    Roles,
    SelectedField,
    ValidateFilters
} from "./Types";
import {
    addLoading,
    buildHeadersFromUser,
    buildURL,
    checkOnBehalfOfUser,
    getAPIServer,
    getConnectionTypeByValue,
    getUser,
    handleError,
    handleErrorResponse,
    handleNetworkError, hasRole, isAdmin, isCustomer,
    removeLoading,
} from "./Utils";
import {TfiSave} from "react-icons/tfi";
import {toast} from "react-toastify";
import AttributeFilter from "./AttributeFilter";
import DataFeedComponent from "./DataFeedComponent";
import CustomerURLValidation from "./CustomerURLValidation";
import AlertComponent from "../common/AlertComponent";
import ConfirmationDialog from "../common/ConfirmationDialog";
import CustomerPermissionsCarousel from "./CustomerPermissionsCarousel";
import {Tooltip} from "react-tooltip";
import URLParamsReader from "./URLParamsReader";
import UrlParamsUpdater from "./UrlParamsUpdater";

interface DashboardProps{
    role: string[] | undefined
}


function getSelectedOptions(filters: SelectedField[]) {
    return filters ? filters.map(f => {
        return {
            label: f.field,
            value: f.field
        }
    }): [];
}



function Dashboard(props: DashboardProps) {


    const { role } = props;
    const connectionTypesOptions = connectionTypes;
    const [connectionType, setConnectionType] = useState<FieldOption | null>(getConnectionTypeByValue('snapshot'));
    const [availableFields, setAvailableFields] = useState<FieldOption[]>([]);
    const [loadingFields, setLoadingFields] = useState<boolean>(false);
    const [selectedFields, setSelectedFields] = useState<SelectedField[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<FieldOption[]>([]);
    const [latestSelectedField, setLatestSelectedField] = useState<SelectedField | null>(null);
    const [conflation, setConflation] = useState<number>(0);
    const [snapshotAge, setSnapshotAge] = useState<number>(30);
    const [delta, setDelta] = useState<boolean>(true);
    const [selectedCustomer, setSelectedCustomer] = useState<FieldOption | null>(null);
    const [sseConnected, setSseConnected] = useState<boolean>(false);
    const [fetchingData, setFetchingData] = useState<boolean>(false);
    const [origin, setOrigin] = useState<string | null>(null);
    const [customerNotes, setCustomerNotes] = useState<string>('');
    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        title: "",
        message: "",
        variant: "success",
        show: false
    });
    const [filtersIndex, setFiltersIndex] = useState<number | null>(null);
    const [showConfirmationDeleteCustomerFiltersModal, setShowConfirmationDeleteCustomerFiltersModal] = useState<boolean>(false);
    const [customer, setCustomer] = useState<CustomerInfo | null>(null);
    const customerPermissionsRef = useRef<any>([]);
    const permissionsEverythingRef = useRef<boolean>(false);
    const [customerNotesStyle, setCustomerNotesStyle] = useState<any>({});
    const [eventCounter, setEventCounter] = useState<number>(0);
    const [validFilters, setValidFilters] = useState<ValidateFilters>({
        className: '',
        event: eventCounter
    });

    const animatedComponents = makeAnimated();


    useEffect(() => {
        async function loadFields(){
            setLoadingFields(true);
            let requestHeaders = buildHeadersFromUser();
            checkOnBehalfOfUser(requestHeaders, origin, selectedCustomer ? selectedCustomer.value : null);
            addLoading();
            setAvailableFields([]);
            const fetchOptions: RequestInit = {
                method: "GET",
                headers: requestHeaders
            }

            fetch(`${getAPIServer()}/fenixa/fields`, fetchOptions)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleErrorResponse(response);
                    }
                })
                .then(data => {
                    if (data) {
                        setAvailableFields(
                            data.sort()
                                .map((d: FieldItem) => {
                                    return {
                                        label: d.name,
                                        value: d.name,
                                        type: d.type
                                    }
                                })
                        );
                    }
                })
                .finally(() => {
                    setLoadingFields(false);
                    removeLoading();
                })
                .catch((error) => {
                    handleNetworkError(error);
                });
        }

        if(selectedCustomer) {
            loadFields()
                .then(() => console.log('loaded fields according to user ', selectedCustomer));
        }

    }, [selectedCustomer]);


    useEffect(() => {
        setSelectedOptions(getSelectedOptions(selectedFields));
        if(selectedFields.length > 0 && validFilters.event < eventCounter){
            setValidFilters({
                className: '',
                event: eventCounter
            });
        }
    }, [selectedFields, eventCounter]);

    useEffect(() => {
        if(customer){
            if (origin !== 'sub') {
                toast.info(`Loaded filters for customer ${customer.name}`, {
                    autoClose: 500
                });
            }
        }
    }, [customer]);

    useEffect(() => {
        if((!customer && selectedCustomer && selectedCustomer.value !== '0') || (customer && selectedCustomer && customer.oid !== selectedCustomer.value && selectedCustomer.value !== '0')){
            addLoading();
            const requestHeaders = buildHeadersFromUser();
            fetch(`${getAPIServer()}/fenixa/customer/${selectedCustomer.value}`, {
                method: 'GET',
                headers: requestHeaders,
            })
                .then(response => {
                    if(response.ok) {
                        return response.json();
                    }else{
                        handleErrorResponse(response);
                    }
                })
                .then(data => {
                    if(data) {
                        if (data.permissions !== '' && data.permissions !== '__EVERYTHING__') {
                            customerPermissionsRef.current = JSON.parse(data.permissions);
                        } else {
                            customerPermissionsRef.current = [];
                        }
                        if(data.permissions !== ''){
                            permissionsEverythingRef.current = (data.permissions === '__EVERYTHING__');
                        }
                        setCustomer({...data});
                    }
                })
                .finally(()=>{
                    removeLoading();
                })
                .catch((error)=>{
                    handleNetworkError(error);
                })
        }

    }, [selectedCustomer]);

    function loadCustomerPermissions(index: number){
        setSelectedFields(customerPermissionsRef.current[index]);
        setFiltersIndex(index);
        setLatestSelectedField(null);
    }

    function removeCustomerPermissionsByIndex(index: number){
        setFiltersIndex(index);
        setShowConfirmationDeleteCustomerFiltersModal(true);
    }

    function addFilters(){
        setLatestSelectedField(null);
        setSelectedFields([]);
        setSelectedOptions([]);
        setFiltersIndex(-1);
    }


    function removeFiltersByIndex(){
        addLoading();
        customerPermissionsRef.current.splice(filtersIndex, 1);
        const requestHeaders = buildHeadersFromUser();
        if (selectedCustomer && customer && isCustomerSelected()) {
            //@ts-ignore
            fetch(`${getAPIServer()}/fenixa/customer/${customer.oid}/filters`, {
                method: 'DELETE',
                headers: requestHeaders,
                body: JSON.stringify({
                    oid: customer.oid,
                    permissions: JSON.stringify(customerPermissionsRef.current)
                })
            })
                .then(response => {
                    if(response.ok) {
                        return response.json();
                    }else{
                        handleErrorResponse(response);
                    }
                })
                .then(data => {
                    if(data) {
                        setShowConfirmationDeleteCustomerFiltersModal(false);
                        setCustomer({...data});
                        setFiltersIndex(-1);
                        customerPermissionsRef.current = JSON.parse(data.permissions);
                        setSelectedFields([]);
                        setSelectedOptions([]);
                        setCustomerNotes('');
                        toast.success(`Saved filters for customer ${selectedCustomer.label}`, {
                            autoClose: 1000
                        });
                    }
                })
                .finally(()=>{
                    removeLoading();
                })
                .catch((error)=>{
                    handleNetworkError(error);
                })
        }
    }

    function saveCustomerFilters(){
        setEventCounter(eventCounter=>++eventCounter);
        if(customerNotes === '') {
            setCustomerNotesStyle({
                border: "1px solid #dc3545"
            });
        }else{
            setCustomerNotesStyle({});
        }
        if(!hasMissingFieldsValues()) {
            if (selectedFields.length > 0) {
                setValidFilters({
                    className: '',
                    event: eventCounter
                });
                if(customerNotes && customerNotes !== '') {
                    if(!hasMissingFieldsValues()) {
                        addLoading();
                        const requestHeaders = buildHeadersFromUser();
                        if ((filtersIndex == null || filtersIndex === -1)) {
                            customerPermissionsRef.current.push(selectedFields);
                        } else if(filtersIndex != null){
                            customerPermissionsRef.current[filtersIndex] = selectedFields;
                        }
                        if (isCustomerSelected()) {
                            fetch(`${getAPIServer()}/fenixa/customer/${selectedCustomer?.value}`, {
                                method: 'POST',
                                headers: requestHeaders,
                                body: JSON.stringify({
                                    oid: selectedCustomer?.value,
                                    name: selectedCustomer?.label,
                                    permissions: JSON.stringify(customerPermissionsRef.current),
                                    notes: customerNotes
                                })
                            })
                                .then(response => {
                                    if (response.ok) {
                                        return response.json();
                                    } else {
                                        handleErrorResponse(response);
                                    }
                                })
                                .then(data => {
                                    if (data) {
                                        setCustomer({...data});
                                        customerPermissionsRef.current = JSON.parse(data.permissions);
                                        setFiltersIndex(-1);
                                        setSelectedFields([]);
                                        setSelectedOptions([]);
                                        setCustomerNotes('');
                                        toast.success(`Saved filters for customer ${selectedCustomer?.label}`, {
                                            autoClose: 1000
                                        });
                                    }
                                })
                                .finally(() => {
                                    removeLoading();
                                })
                                .catch((error) => {
                                    handleNetworkError(error);
                                })
                        }
                    }
                }
            }else{
                setValidFilters({
                    className: 'react-select-invalid',
                    event: eventCounter
                });
            }
        }
    }

    function removeAttribute(attributeName: string){
        setSelectedOptions(selectedOptions.filter(sf => sf["value"] !== attributeName));
        setSelectedFields(selectedFields.filter(sf => sf.field !== attributeName));
        setLatestSelectedField(null);
    }

    function updateSelectedFields(field: SelectedField) {
        setSelectedFields((prevArray: SelectedField[])=>
            prevArray.map((obj: SelectedField)=>
                obj.field === field.field ? {...field} : obj
            ));
        setLatestSelectedField(field);
    }


    function updateFieldsFromURL(url: string){
        let selectedFieldsByURL: any[] = [];
        try {
            const customerUrl = new URL(url);
            const urlPaths = customerUrl.pathname.split("/");
            if(urlPaths.length !== 3 || (urlPaths.length === 3 && !["snapshot", "subscribe"].includes(urlPaths[2]))){
                handleError("URL is not well formed. Should be /webapi/snapshot or /webapi/subscribe");
                return false;
            }
            setConnectionType(getConnectionTypeByValue(urlPaths[2]));
            // Get all parameters as an object
            const params: any = {};
            customerUrl.searchParams.forEach((value, key) => {
                params[key] = key in params ? [...params[key], value] : [value];
            });

            let wrongFieldNames: string[] = []
            let processedFields: string[] = [];
            Object.keys(params).forEach((k: string) => {
                let fieldName = k;
                if(!fieldName.startsWith("_")) {
                    if (fieldName.endsWith("_r")) {
                        fieldName = fieldName.substring(0, fieldName.length - 2);
                    }
                    if (availableFields.map((f: FieldOption) => f.value).includes(fieldName)) {
                        if (!processedFields.includes(fieldName)) {
                            processedFields.push(fieldName);
                            let field = {
                                "field": fieldName,
                                "filters": getFiltersByField(fieldName, params)
                            }
                            selectedFieldsByURL.push(field);
                        }
                    } else {
                        wrongFieldNames.push(fieldName);
                    }
                }else{
                    switch (k){
                        case "_snapshot_age":   setSnapshotAge(parseInt(params[k]));
                            break;
                        case "_conflation":     setConflation(parseInt(params[k]));
                            break;
                        case "_delta":          setDelta(params[k][0] === "true");
                            break;
                    }
                }
            });
            setSelectedFields(selectedFieldsByURL);
            setSelectedOptions(getSelectedOptions(selectedFieldsByURL));
            if (wrongFieldNames.length !== 0) {
                toast.warning(`The fields [${wrongFieldNames.join(",")}] are not valid`);
            }
        }catch (e) {
            toast.warning(`Not a valid URL`);
        }
    }

    function getFiltersByField(fieldName: string, params: any) {
        let filters: any = {}
        Object.keys(params).forEach((k : string)=>{
            if(k === fieldName){
                filters["match"] = params[k];
            }
            filters["regExp"] = `${fieldName}_r` in params ? params[`${fieldName}_r`] : [];
        });
        return filters;
    }

    function disconnectSSE(){
        if(connectionType && connectionType.value === 'subscribe') {
            setSseConnected(false);
        }
    }

    function pullData() {
        setEventCounter(eventCounter=>++eventCounter);
        if(!hasMissingFieldsValues()) {
            if(selectedFields.length > 0) {
                setValidFilters({
                    className: '',
                    event: eventCounter
                });
                if (connectionType && connectionType.value === 'subscribe') {
                    setSseConnected(true);
                    setFetchingData(false);
                } else {
                    setSseConnected(false);
                    setFetchingData(fetchData => !fetchData);
                }
            }else {
                setValidFilters({
                    className: 'react-select-invalid',
                    event: eventCounter
                });
            }
        }
    }

    function isNewCustomer(){
        return !customer && selectedCustomer && selectedCustomer.value !== '0';
    }


    function isCustomerSelected(){
        return selectedCustomer && selectedCustomer.value !== '0';
    }


    function isCustomerWithoutPermissions(){
        if(isCustomer(role)){
            if(customerPermissionsRef.current != null && Object.keys(customerPermissionsRef.current).length >= 0
                && customerPermissionsRef.current.permissions === 'Pending'){
                return true;
            }
            if(customerPermissionsRef.current == null){
                return true;
            }
        }
        return false;
    }


    function isValidOptionByRole(newValue: MultiValue<FieldOption>, name: string, type: string){
        if(name && isCustomer(role)) {
            const fieldsValues = newValue.map(n=>n.value);
            if(name !== "fields"){
                const filtersByField = customerPermissionsRef.current.filter((cp:any) => cp.field === name);
                if(filtersByField.length === 1) {
                    let validMatchFields = true;
                    fieldsValues.forEach((k:string) =>{
                        if(!filtersByField[0]["filters"][type].includes(k)){
                            toast.warning(`Not allowed option ${k} for field ${name}`);
                            validMatchFields = false;
                        }
                    })
                    if (!validMatchFields) {
                        return validMatchFields;
                    }
                }
            }
            return true;
        }
        return true;
    }


    const MultiValueLabel = (props: MultiValueGenericProps<FieldOption>) => {
        const optionValue = props.data.value;
        const optionType: FieldOption[] = props.selectProps.options.filter((option: any) : option is FieldOption =>
            option.hasOwnProperty("value") && option.hasOwnProperty("label") && option.value === optionValue
        );
        let isInternal = false;
        let deprecated = false;

        if (optionType.length === 1) {
            isInternal = optionType[0].type === 'internal';
            deprecated = optionType[0].type === 'deprecated';
        }
        let children = <span>{props.data.label}</span>;
        if(isInternal || deprecated){
            const tooltipId = `field_${props.data.value}`;
            const message = isInternal ? "Internal field, can only be used to permission a client" :
                "This field is not in use anymore";
            children = <div>
                <div
                    style={{textDecoration: "none"}}
                    data-tooltip-id={tooltipId}
                    data-tooltip-content={message}
                    data-tooltip-place="right">
                    <span>{props.data.label}</span>
                    <span className={"internal-label-small-text"}>{isInternal ? '[Internal]' : '[Unavailable]'}</span>
                </div>
                <Tooltip id={tooltipId} />
            </div>;
        }

        return (
            <>
                <components.MultiValueLabel {...props}>
                    {children}
                </components.MultiValueLabel>
            </>
        );
    };

    const Option = (props: OptionProps<FieldOption>) => {
        let suffix = props.data.type === 'internal' ? <span style={{fontSize: "12px", marginLeft: "5px"}}>[Internal]</span> : null;
        return (
            <>
                <components.Option {...props} >
                    {props.data.label}
                    {suffix}
                </components.Option>
            </>
        );
    };

    const ClearIndicator = (props: ClearIndicatorProps<FieldOption, true>) => {
        const CustomClearText: FunctionComponent = () => <>clear all</>;
        const {
            children = <CustomClearText />,
            getStyles,
            innerProps: { ref, ...restInnerProps },
        } = props;
        return (
            <div
                {...restInnerProps}
                ref={ref}
                style={getStyles('clearIndicator', props) as CSSProperties}
            >
                <div style={{ padding: '0px 5px', cursor: "pointer" }}>{children}</div>
            </div>
        );
    };

    const saveButtonTitle = (filtersIndex === null || filtersIndex === -1) ? 'Add Customer Filters' : 'Save Customer Filters';

    let permissionsUserTitle = null;
    if(selectedCustomer){
        permissionsUserTitle = `${selectedCustomer.label} Permissions`;
        if(isNewCustomer()){
            permissionsUserTitle = `Add permissions to ${selectedCustomer.label}`;
        }
        if(origin && origin === 'sub'){
            permissionsUserTitle = `On behalf of ${selectedCustomer.label}`;
        }
    }


    function isInspectionByMyself(){
        const user = getUser();
        if(user != null && selectedCustomer != null) {
            return (user.profile.sub === selectedCustomer.value);
        }else {
            return false;
        }
    }

    function hasMissingFieldsValues() {
        let missingValues = false;
        selectedFields.forEach(sf=>{
           if(sf.filters["match"].length === 0 && sf.filters["regExp"].length === 0){
               missingValues = true;
           }
        });
        return missingValues;
    }


    return (
        <div>
            <Row hidden={sseConnected || fetchingData || isCustomerWithoutPermissions()}>
                <Col xs={12}>
                    <fieldset>
                        <legend>API</legend>
                    </fieldset>
                    <Row>
                        <Col id={"idConnectionType"} style={{maxWidth: "180px"}}>
                            <Select options={connectionTypesOptions}
                                    value={connectionType}
                                    isMulti={false}
                                    components={animatedComponents}
                                    onChange={(newValue: SingleValue<FieldOption>)=>{
                                        if(newValue) {
                                            setConnectionType(newValue);
                                        }
                                    }}
                                    name={"connectionType"}
                                    placeholder={"Connection Type"}
                                    aria-labelledby={"idConnectionType"}
                            />
                        </Col>
                        {connectionType &&
                            <Col hidden={connectionType.value === 'snapshot'} style={{maxWidth: "225px"}}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="inputGroup-sizing-conflation">Conflation (secs)</InputGroup.Text>
                                    <FormControl
                                        id={"conflateId"}
                                        aria-label="Default"
                                        type={"numeric"}
                                        aria-describedby="inputGroup-sizing-conflation"
                                        value={conflation}
                                        onChange={(event) => {
                                            if(event.target.value) {
                                                setConflation(parseInt(event.target.value));
                                            }else {
                                                setConflation(0);
                                            }
                                        }}
                                        style={{maxWidth: "50px"}}
                                    />
                                </InputGroup>
                            </Col>
                        }
                        <Col style={{maxWidth: "220px"}}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-age">Snapshot (age)</InputGroup.Text>
                                <FormControl
                                    id={"snapshotAge"}
                                    aria-label="Default"
                                    type={"numeric"}
                                    aria-describedby="inputGroup-sizing-age"
                                    value={snapshotAge}
                                    onChange={(event) => {
                                        if(event.target.value) {
                                            const age = parseInt(event.target.value);
                                            setSnapshotAge(age < 0 ? age * -1 : age);
                                        }else {
                                            setSnapshotAge(0);
                                        }
                                    }}
                                    style={{maxWidth: "50px"}}
                                />
                            </InputGroup>
                        </Col>
                        {
                            connectionType &&
                            <Col hidden={connectionType.value === 'snapshot'}>
                                <FormCheck
                                    id={"idDelta"}
                                    style={{marginTop: "15px"}}
                                    inline={true}
                                    type={"checkbox"}
                                    label={"Delta"}
                                    checked={delta}
                                    onChange={(event) => {
                                        setDelta(event.target.checked);
                                    }}
                                />
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
            {selectedCustomer &&
                <Row hidden={!hasRole(role, Roles.ADMIN) || !isCustomerSelected() || sseConnected || fetchingData || isInspectionByMyself()}>
                    <Col>
                        <fieldset>
                            <legend>
                                {permissionsUserTitle}
                                <CloseButton className={`icon-button`}
                                             style={{marginLeft: "15px", fontSize: "medium"}}
                                             onClick={()=>{
                                                 const user = getUser();
                                                 if( user != null
                                                     && user.profile
                                                     && user.profile.name && user.profile.name !== ''
                                                     && user.profile.sub && user.profile.sub !== ''
                                                 ) {
                                                     setSelectedCustomer({
                                                         label: '',
                                                         value: "0"
                                                     });
                                                 }
                                             }}
                                />
                            </legend>
                            <CustomerPermissionsCarousel permissions={customerPermissionsRef.current}
                                                         permissionsEverything={permissionsEverythingRef.current}
                                                         loadCustomerPermissions={loadCustomerPermissions}
                                                         removeCustomerPermissions={removeCustomerPermissionsByIndex}
                                                         selectedFiltersSet={filtersIndex}
                                                         cancelEdit={()=>addFilters()}
                                                         readonly={!!(origin && origin === 'sub')}
                            />
                        </fieldset>
                    </Col>
                </Row>
            }
            {
                !sseConnected && !fetchingData && !isCustomerWithoutPermissions() &&
                <Row>
                    <Col>
                        <fieldset>
                            <legend>Filters</legend>
                            <Select options={availableFields}
                                    value={selectedOptions ? selectedOptions : []}
                                    isLoading={loadingFields}
                                    isDisabled={loadingFields}
                                    isMulti
                                    components={{...animatedComponents, MultiValueLabel, Option, ClearIndicator}}
                                    onChange={(newValue: MultiValue<FieldOption>)=>{
                                        const attributeValues = newValue.map(n => n["value"]);
                                        if(newValue && isValidOptionByRole(newValue, "fields", '')) {
                                            setSelectedFields(prevSelectedFields => {
                                                return attributeValues.map((v: string) => {
                                                    const existingField = prevSelectedFields.find(sf => sf.field === v);
                                                    if (existingField) {
                                                        return existingField;
                                                    }
                                                    return {
                                                        field: v,
                                                        filters: {
                                                            match: [],
                                                            regExp: []
                                                        }
                                                    };
                                                });
                                            });
                                            setSelectedOptions([...newValue]);
                                        }
                                    }}
                                    name={"fields"}
                                    placeholder={"Select one or more attributes"}
                                    className={validFilters.className}
                                    classNamePrefix="filter-fields-select"
                            />
                            {
                                validFilters.className !== '' &&
                                <div className={"invalid-filter"}>Please provide at least one filter</div>
                            }
                        </fieldset>
                    </Col>
                </Row>
            }

            {
                !fetchingData && !sseConnected && selectedFields && selectedFields.map((d, index) => {
                    return <AttributeFilter key={index}
                                            field={d.field}
                                            selectedFields={selectedFields}
                                            updateSelectedFields={updateSelectedFields}
                                            isValidOption={isValidOptionByRole}
                                            latestSelectedField={latestSelectedField}
                                            removeAttribute={removeAttribute}
                                            eventCounter={eventCounter}
                    />
                })
            }
            {connectionType &&
                <Row>
                    <Col>
                        <CustomerURLValidation permissions={selectedFields}
                                               selectedCustomer={selectedCustomer?.label}
                                               connectionType={connectionType}
                                               delta={delta}
                                               snapshotAge={snapshotAge}
                                               conflation={conflation}
                                               apiFields={availableFields}
                                               updateFieldsFromURL={updateFieldsFromURL}
                                               sseConnected={sseConnected}
                                               fetchingData={fetchingData}

                        />
                    </Col>
                </Row>
            }
            {
                selectedCustomer && hasRole(role, Roles.ADMIN) &&
                <Row hidden={fetchingData || sseConnected || origin === 'sub' || selectedCustomer.value === '0'}
                        style={{marginBottom: "10px"}}>
                    <Col>
                        <fieldset>
                            <legend>Notes</legend>
                            <FormControl id={"idNotes"} as="textarea" rows={3}
                                         title={"Notes"}
                                         value={customerNotes}
                                         style={customerNotesStyle}
                                         onChange={(event)=>{
                                             setCustomerNotes(event.target.value);
                                         }}/>
                            {
                                Object.keys(customerNotesStyle).length > 0 &&
                                <div className={"invalid-filter"}>Please provide notes about the customer permissions</div>
                            }
                        </fieldset>
                    </Col>
                </Row>
            }
            {
                connectionType &&
                <DataFeedComponent sseConnected={sseConnected}
                                   fetchingData={fetchingData}
                                   pullData={pullData}
                                   disconnectSSE={disconnectSSE}
                                   setFetchingData={setFetchingData}
                                   connectionType={connectionType}
                                   buildURL={()=>buildURL(selectedFields, connectionType, snapshotAge, delta, conflation)}/>
            }
            {
                (!fetchingData && !sseConnected)
                && isAdmin(role)
                && isCustomerSelected()
                && (!origin || origin !== 'sub')
                &&
                <Button size={"sm"}
                        style={{marginRight: "10px", float: "right"}}
                        onClick={()=>{
                            saveCustomerFilters()
                        }}>
                    <TfiSave /> {saveButtonTitle}
                </Button>
            }
            <Row hidden={!isCustomerWithoutPermissions()}>
                <Col>
                    <AlertComponent
                        alertInfo={{
                            message: "User not assigned any permissions",
                            variant: "warning",
                            title: "Pending to assign permissions",
                            show: true
                        }}
                        handleClose={()=>setAlertInfo({...alertInfo,
                            show: false
                        })}
                    />
                </Col>
            </Row>
            {
                showConfirmationDeleteCustomerFiltersModal &&
                <ConfirmationDialog message={"Are you sure to remove this filters?"}
                                    show={showConfirmationDeleteCustomerFiltersModal}
                                    handleClose={()=>{
                                        setShowConfirmationDeleteCustomerFiltersModal(false);
                                        setFiltersIndex(-1);
                                    }}
                                    handleConfirm={()=>removeFiltersByIndex()}/>
            }
            <URLParamsReader setConnectionType={setConnectionType}
                             setSelectedFields={setSelectedFields}
                             setConflation={setConflation}
                             setSnapshotAge={setSnapshotAge}
                             setDelta={setDelta}
                             setSelectedCustomer={setSelectedCustomer}
                             setSseConnected={setSseConnected}
                             setFetchingData={setFetchingData}
                             setOrigin={setOrigin}
                             setFiltersIndex={setFiltersIndex}
            />
            <UrlParamsUpdater connectionType={connectionType}
                              setConnectionType={setConnectionType}
                              selectedFields={selectedFields}
                              setSelectedFields={setSelectedFields}
                              conflation={conflation}
                              setConflation={setConflation}
                              snapshotAge={snapshotAge}
                              setSnapshotAge={setSnapshotAge}
                              delta={delta}
                              setDelta={setDelta}
                              selectedCustomer={selectedCustomer}
                              setSelectedCustomer={setSelectedCustomer}
                              sseConnected={sseConnected}
                              setSseConnected={setSseConnected}
                              fetchingData={fetchingData}
                              setFetchingData={setFetchingData}
                              origin={origin}
                              setOrigin={setOrigin}
                              filtersIndex={filtersIndex}
                              setFiltersIndex={setFiltersIndex}
            />
        </div>
    );
}

export default Dashboard;
