import React, {useEffect, useState} from "react";
import {Container, Dropdown, Nav, Navbar} from "react-bootstrap";
import logo from "../../public/fenixamd.png";
import {SignOutButton} from "./SignOutButton";
import {Navigate, Route, Routes} from "react-router";
import {ToastContainer} from "react-toastify";
import {
    buildHeadersFromUser,
    getAPIServer,
    getEnvironment,
    getUser,
    getUserRole,
    getVersion,
    handleErrorResponse,
    handleNetworkError,
    hasRole
} from "../components/Utils";
import {NotGrantedUser} from "./NotGrantedUser";
import {Roles} from "../components/Types";
import Entitlements from "../components/Entitlements";
import Subscriptions from "../components/Subscriptions";
import {useLocation} from "react-router-dom";
import {LicenseManager} from "@ag-grid-enterprise/core";
import {useAuth, withAuthenticationRequired} from "react-oidc-context";
import {Landing} from "./Landing";
import Callback from "../components/Callback";
import Dashboard from "../components/Dashboard";
import {VscVersions} from "react-icons/vsc";
import {LuUser} from "react-icons/lu";

const DashboardPrivate = withAuthenticationRequired(Dashboard, {
    OnRedirecting: () => (<Landing/>)
});

const EntitlementsPrivate = withAuthenticationRequired(Entitlements, {
    OnRedirecting: () => (<Landing/>)
});

const SubscriptionsPrivate = withAuthenticationRequired(Subscriptions, {
    OnRedirecting: () => (<Landing/>)
});

interface ProtectedComponentProps{
    setIsLoggingOut: Function
}


export const ProtectedComponent = (props: ProtectedComponentProps) => {
    const { setIsLoggingOut } = props;
    const [environment] = useState(getEnvironment());
    const [role, setRole] = useState<string[] | undefined>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const location = useLocation();
    const [hasLicense, setHasLicense] = useState<boolean>(false);
    const auth = useAuth();
    const user = getUser();
    const version = getVersion();

    useEffect(() => {
        const requestHeaders = buildHeadersFromUser();
        fetch(`${getAPIServer()}/fenixa/__aggrid/license`, {
            method: 'GET',
            headers: requestHeaders
        })
            .then(response => {
                if (response.ok) {
                    return response.text();
                } else {
                    setHasLicense(true);
                    handleErrorResponse(response);
                }
            })
            .then(licenseKey => {
                if (licenseKey) {
                    LicenseManager.setLicenseKey(
                        licenseKey
                    );
                    setHasLicense(true);
                }
            })
            .catch((error) => {
                setHasLicense(true);
                handleNetworkError(error);
            });
    }, []);

    const webAPIEnv = environment === 'PROD' ? '' : ('['+environment+']');
    let title = document.getElementById('idWebAPITitle');
    if (title) {
        title.innerText = environment + " - Fenixa";
    }

    useEffect(() => {
        const userRole: string[] | undefined = getUserRole();
        if(!userRole){
            setErrorMessage("User has not role");
        }else{
            setRole(userRole);
        }
    }, []);


    if(!role && auth.isAuthenticated && errorMessage !== ''){
        return <div className="App">
                    <Container>
                        <Navbar fixed={"top"} collapseOnSelect={true} expand="lg" className="bg-body-tertiary navbar-slim" data-bs-theme="dark" style={{marginLeft: "12px", marginRight:"12px"}}>
                            <Container>
                                <Navbar.Brand href="/">
                                    <img src={logo} alt="Fenixa" width={40} height={40} style={{marginRight: "15px"}}/>
                                    Fenixa {webAPIEnv}
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                                    <Dropdown align="end" >
                                        <Dropdown.Toggle className="bg-body-tertiary navbar-slim dropdown-username" variant={"outline-light"}>
                                            <LuUser style={{marginRight: "5px"}}/> {user?.profile.name}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#">
                                                <VscVersions style={{marginRight: "5px"}}/>Version {version}
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <SignOutButton setIsLoggingOut={setIsLoggingOut}/>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </Container>
                    <NotGrantedUser environment={environment} message={errorMessage}/>
                    <ToastContainer position="top-right"
                                    autoClose={3000}
                                    closeOnClick
                    />
            </div>
    }

    function checkActiveTab(tabName: string){
        return location.pathname === tabName;
    }


    return role && hasLicense ? (
        <div style={{ height: '100vh', position: 'relative' }}>
            <div className="App">
                <Navbar fixed={"top"} collapseOnSelect={true} expand="lg" className="bg-body-tertiary navbar-slim" data-bs-theme="dark" style={{marginLeft: "12px", marginRight:"12px"}}>
                    <Container>
                        <Navbar.Brand href="/">
                            <img src={logo} alt="Fenixa" width={40} height={40} style={{marginRight: "15px"}}/>
                            Fenixa {webAPIEnv}
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link id={"homeLink"} href="/" active={checkActiveTab('/')}>
                                    Query Builder
                                </Nav.Link>
                                {hasRole(role, Roles.ADMIN) &&
                                    <Nav.Link href="/entitlements" active={checkActiveTab('/entitlements')}>
                                        Entitlements
                                    </Nav.Link>
                                }
                                <Nav.Link href="/subscriptions" active={checkActiveTab('/subscriptions')}>
                                    Subscriptions
                                </Nav.Link>
                            </Nav>
                            <Dropdown align="end" >
                                <Dropdown.Toggle className="bg-body-tertiary navbar-slim dropdown-username" variant={"outline-light"}>
                                    <LuUser style={{marginRight: "5px"}}/> {user?.profile.name}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">
                                        <VscVersions style={{marginRight: "5px"}}/>Version {version}
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <SignOutButton setIsLoggingOut={setIsLoggingOut}/>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <div className={"container-top"}>
                    <Container>
                        <Routes>
                            <Route path="/" element={<DashboardPrivate role={role} />}/>
                            <Route path="/entitlements" element={<EntitlementsPrivate />}/>
                            <Route path="/subscriptions" element={<SubscriptionsPrivate role={role} />}/>
                            <Route path="/signin" element={<Callback />}/>
                            <Route path={"*"} element={<Navigate to={"/"} replace={true}/>}/>
                        </Routes>
                    </Container>
                </div>
                <ToastContainer position="top-right"
                                autoClose={3000}
                                closeOnClick
                />
            </div>
        </div>
    ) :
    (
        <></>
    )
}