import React, {useEffect, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import logo from "../../public/fenixamd.png";
import SignInButton from "./SignInButton";
import TeamsLinkUserMessage from "./TeamsLinkUserMessage";
import {UserInfo} from "../components/Types";
import {getFenixaAdmins, getUser, handleError} from "../components/Utils";
import {useAuth} from "react-oidc-context";

interface NotGrantedUserProps{
    environment: string,
    message?: string
}
export const NotGrantedUser = (props: NotGrantedUserProps) => {
    const {
        environment,
        message
    } = props;

    const auth = useAuth();
    const [groupOwners] = useState<UserInfo[]>(getFenixaAdmins());

    useEffect(() => {
        if(!auth.isAuthenticated && !message) {
            const user = getUser();
            handleError(`${user?.profile.name} is not allowed to access Fenixa`);
        }
    }, []);

    useEffect(() => {
        if(message){
            setTimeout(()=>{
                handleError(`${message}`);
            }, 1000)
        }
    }, [message]);

    const predefinedMessage = `Please could do you grant me access to Fenixa ${environment}`;

    return (
        <Container className={"landing-container"} style={{position: "fixed", top: "40px"}}>
            <Row>
                <Col className={"card-container"}>
                    <div className="centered-card">
                        <Card style={{width: "400px", height: "100%"}}>
                            <Card.Img variant="top" src={logo} style={{padding: "10px"}}/>
                            <Card.Body className="left-aligned-content">
                                <Card.Title style={{textAlign: "center"}}>Fenixa</Card.Title>
                                <Card.Text>
                                    Please contact with any admin
                                    {groupOwners.map((g, index) => {
                                        return <TeamsLinkUserMessage key={index} user={g.display} email={g.email} message={predefinedMessage} topicChat={"Fenixa Grant Access"}/>
                                    })}
                                </Card.Text>
                                {
                                    !auth.isAuthenticated &&
                                    <Card.Footer style={{backgroundColor: "transparent", textAlign: "center"}}>
                                        <SignInButton variant={"outline-primary"}/>
                                    </Card.Footer>
                                }
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}