import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

import {BrowserRouter} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import {getAuthority, getClientId} from "./components/Utils";
import {AuthProvider} from "react-oidc-context";
import {config} from "./auth/UserManagement";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const clientId = getClientId();
const authority = getAuthority();

let url= window.location.origin;

let authConfig = {...config};
authConfig.client_id = clientId;
authConfig.authority= authority;
authConfig.target = `${url}/signin`;
authConfig.base = `${url}`;
authConfig.logoutRedirect = `${url}`;
authConfig.post_logout_redirect_uri = `${url}`;
authConfig.redirect_uri = `${url}/signin`;

root.render(
  <React.StrictMode>
      <BrowserRouter>
          <AuthProvider {...authConfig}>
            <App />
          </AuthProvider>
      </BrowserRouter>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
