import React from 'react';
import {Button, Modal} from "react-bootstrap";

interface ConfirmationDialogProps{
    message: string,
    show: boolean;
    handleClose: Function;
    handleConfirm: Function;
}


function ConfirmationDialog(props: ConfirmationDialogProps) {


    const { message, show, handleClose, handleConfirm } = props;

    return (
        <Modal show={show} onHide={()=>handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Action</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>handleClose()}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={()=>handleConfirm()}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );

}

export default ConfirmationDialog;
