import {WebStorageStateStore} from 'oidc-client-ts';

//Keycloak
export const config = {
    authority: 'https://lonfenicsmd-vd1.systems.london.cantor.com:9443/realms/fenics-market-data-develop',
    target: 'http://localhost:3000/signin',
    logoutRedirect: 'http://localhost:3000/',
    base: 'http://localhost:3000',
    response_type: "code",
    scope: "openid profile email",  // Specify the scopes you need
    client_id: "FenicsMarketDataWebAPI",
    post_logout_redirect_uri: "http://localhost:3000/",
    redirect_uri: "http://localhost:3000/signin",
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    _provider: "Keycloak"
}
