import React, {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AgGridReact} from '@ag-grid-community/react';
import {toast} from "react-toastify";

import {JSONParser, StackElement} from '@streamparser/json';
import {JsonObject} from "@streamparser/json/dist/mjs/utils/types/jsonTypes";
import {Col, Row} from "react-bootstrap";
import _ from "lodash";
import TotalMessagesQuotes from "./TotalMessagesQuotes";
import {
    buildHeadersFromToken,
    checkOnBehalfOfUser,
    checkOnBehalfOfUserToObject,
    getUser,
    handleError,
    handleErrorResponse
} from "./Utils";
import {useLocation, useSearchParams} from "react-router-dom";

import {ModuleRegistry} from '@ag-grid-community/core';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {ClipboardModule} from "@ag-grid-enterprise/clipboard";
import {EventStreamContentType, fetchEventSource} from '@microsoft/fetch-event-source';
import {useAuth} from "react-oidc-context";
import FallbackError from "../common/FallbackError";
import {ErrorBoundary} from "react-error-boundary";

ModuleRegistry.registerModules([ ClientSideRowModelModule, ClipboardModule ]);

interface AgGridComponentProps{
    sseConnected: boolean,
    fetchingData: boolean,
    buildURL: Function,
    setPause: Function,
    pauseRef: MutableRefObject<boolean>,
    setSnapshotDataComplete: Function
}

const MAX_STREAM_DATA = 1000;
const STATUS_CODE_OK = 200;


function AgGridComponent(props: AgGridComponentProps) {

    let rowsCounter = 0;
    let totalDiscarded = 0;
    const { sseConnected,
            fetchingData,
            buildURL,
            setPause,
            pauseRef,
            setSnapshotDataComplete,
          } = props;

    const auth = useAuth();
    const gridRef = useRef<AgGridReact>(null);
    const [columnDefs, setColumnDefs] = useState<any[]>([]);
    const fetchingDataRef = useRef(false);
    const [displayedRows, setDisplayedRows] = useState<number>(0);
    const displayedRowsRef = useRef(0);
    const [totalReceivedMessages, setTotalReceivedMessages] = useState<number>(0);
    const totalReceivedMessagesRef = useRef(0);
    const columnsDefRef = useRef<any[]>([]);
    const { search } = useLocation();
    const [searchParams] = useSearchParams();
    const [origin, setOrigin] = useState<string>('');
    const [customerOID, setCustomerOID] = useState<string>('');
    const [rowInfo, setRowInfo] = useState<any>(null);
    const user = getUser();
    const agColumnsDefinitionRef = useRef<any[]>([]);
    const [filterUpdated, setFilterUpdated] = useState<number>(0);

    totalReceivedMessagesRef.current = totalReceivedMessages;
    fetchingDataRef.current = fetchingData;
    displayedRowsRef.current = displayedRows;
    columnsDefRef.current = columnDefs;


    useEffect(()=>{
        const customerURL = searchParams.get("customer");
        if(customerURL && customerURL.split("__")[1] !== customerOID){
            setCustomerOID(customerURL.split("__")[1]);
        }
        const originURL = searchParams.get('origin') != null ? searchParams.get('origin') : '';
        if(originURL !== origin){
            setOrigin(originURL != null ? originURL : '');
        }
    }, [search]);


    useEffect(() => {
        if(sseConnected) {
            const ctrl = new AbortController();
            if (auth.isAuthenticated) {
                setTotalReceivedMessages(rowsCounter);
                let streamEventUrl = buildURL();
                let headers = {
                    'Authorization': `Bearer ${user?.id_token}`,
                    'Cache-Control': 'no-cache'
                };
                checkOnBehalfOfUserToObject(headers, origin, customerOID, getUser());

                fetchEventSource(streamEventUrl,
                    {
                        headers: headers,
                        openWhenHidden: true,
                        async onopen(response) {
                            if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
                                toast.success(`Connected to SSE stream`);
                                if (totalReceivedMessagesRef.current === 0 && gridRef.current) {
                                    gridRef.current.api.showNoRowsOverlay();
                                }
                                return; // everything's good
                            }
                            throw new Error(`Failed to start the stream ${await response.text()}`);
                        },
                        async onmessage(msg) {
                            if (msg.event === 'DataMessage') {
                                processRealtimeData(msg);
                            } else if (msg.event === 'StatusMessage') {
                                processStatusMessage(msg);
                            } else if (msg.event !== '') {
                                handleError(`Unknown event: ${msg.event} ${msg.data}`);
                            }
                        },
                        onclose() {
                            toast.warning(`Disconnected from SSE stream`);
                        },
                        onerror(err) {
                            handleError(err.toString());
                            throw err;
                        },
                        signal: ctrl.signal
                    })
                    .catch((error) => {
                        handleError(error);
                    });
                return () => {
                    rowsCounter = 0;
                    setRowInfo(null);
                    setFilterUpdated(0);
                    totalDiscarded = 0;
                    setDisplayedRows(0);
                    ctrl.abort();
                    setColumnDefs([]);
                    setPause(false);
                    toast.warning(`Disconnected from SSE stream`);
                };
            }
        }else{
            setColumnDefs([]);
            rowsCounter = 0; totalDiscarded = 0; agColumnsDefinitionRef.current = [];
            setRowInfo(null);
            setDisplayedRows(0);
            setFilterUpdated(0);
        }
        // eslint-disable-next-line
    }, [sseConnected]);

    useEffect(()=>{
        if(user && user.id_token && user.id_token !== '') {
            setColumnDefs([]);
            setTotalReceivedMessages(0);
            if (fetchingDataRef.current && !sseConnected) {
                fetchSnapshotData(user.id_token).then();
            } else if (!fetchingDataRef.current && !sseConnected) {
                setTotalReceivedMessages(0);
                setRowInfo(null);
                rowsCounter = 0;
                totalDiscarded = 0;
                agColumnsDefinitionRef.current = [];
                setDisplayedRows(0);
                setFilterUpdated(0);
            } else if (!fetchingData) {
                setRowInfo(null);
                rowsCounter = 0;
                totalDiscarded = 0;
                agColumnsDefinitionRef.current = [];
                setDisplayedRows(0);
                setPause(false);
                setFilterUpdated(0);
            }
        }
        // eslint-disable-next-line
    }, [fetchingData, sseConnected]);


    function processRealtimeData(event: any) {
        const data = {...JSON.parse(event.data)};
        if (!pauseRef.current) {
            updateColumnDefinition(data);
            addDataToTable(data);
            setTotalReceivedMessages(++rowsCounter);
        } else{
            ++totalDiscarded;
        }
        const model: any = gridRef.current?.api.getFilterModel();
        setRowInfo({
            data: {...data},
            pause: pauseRef.current,
            counter: rowsCounter,
            discarded: totalDiscarded,
            gridRef: gridRef.current,
            hasFilters: model && Object.keys(model).length > 0
        });
    }


    function processStatusMessage(event: any) {
        const data = {...JSON.parse(event.data)};
        if("status" in data && data.status === 'error'){
            handleError(data.message);
        }
    }


    const updateColumnDefinition = useCallback((data: any) => {
        const dataKeys = Object.keys({ ...data }).sort();
        const currentColumnFields = agColumnsDefinitionRef.current.map(c => c.field).sort();
        if(_.difference(dataKeys, currentColumnFields).length !== 0) {
            const updatedColumnDefs = [...agColumnsDefinitionRef.current];
            dataKeys.forEach(k => {
                if(!currentColumnFields.includes(k)){
                    updatedColumnDefs.push({
                        field: k,
                        headerName: k,
                        filter: "agSetColumnFilter",
                        filterParams: {
                            defaultToNothingSelected: true,
                        },
                        pinned: k === "sym" ? "left" : undefined
                    });
                }
            });
            agColumnsDefinitionRef.current = [...updatedColumnDefs];
            setColumnDefs(updatedColumnDefs);
        }
    },[setColumnDefs]);

    function addDataToTable(data: any){
        if(gridRef && gridRef.current) {
            gridRef.current.api.applyTransactionAsync({
                add: [{...data, _rowId: rowsCounter}]
            });
        }
        if(gridRef && gridRef.current && rowsCounter >= MAX_STREAM_DATA) {
            gridRef.current.api.applyTransactionAsync({
                remove: [{_rowId: rowsCounter - MAX_STREAM_DATA}]
            });
        }
        if(displayedRowsRef.current < MAX_STREAM_DATA){
            setDisplayedRows(prevState => prevState++);
        }
    }

    async function fetchSnapshotData(apiToken: string) {
        try {
            const jsonParser = new JSONParser({ stringBufferSize: undefined, paths: ['$.*'] });
            // @ts-ignore
            jsonParser.onValue = (value: any, key: string, parent: JsonObject, stack: StackElement[]) => {
                const data = value.value;
                if(!pauseRef.current && fetchingDataRef.current) {
                    updateColumnDefinition(data);
                    addDataToTable(data);
                    setTotalReceivedMessages(++rowsCounter);
                }
                if(pauseRef.current){
                    ++totalDiscarded;
                }
                setRowInfo({
                    data: {...data},
                    pause: pauseRef.current,
                    counter: rowsCounter,
                    discarded: totalDiscarded,
                    gridRef: gridRef.current
                });
            };

            const requestHeaders = buildHeadersFromToken(apiToken);
            checkOnBehalfOfUser(requestHeaders, origin, customerOID);
            let snapshotUrl = buildURL();
            const fetchOptions: RequestInit = {
                method: "GET",
                headers: requestHeaders
            }
            const response = await fetch(snapshotUrl, fetchOptions);

            if(response.status === STATUS_CODE_OK) {
                // @ts-ignore
                const reader = response.body.getReader();
                gridRef.current?.api.hideOverlay();
                while (fetchingDataRef.current) {
                    const {done, value} = await reader.read();
                    if (done) {
                        if (totalReceivedMessagesRef.current === 0 && gridRef.current) {
                            gridRef.current.api.showNoRowsOverlay();
                        }
                        toast.info("Snapshot completed", {
                            autoClose: 500
                        });
                        setPause(false);
                        setSnapshotDataComplete(true);
                        break;
                    }
                    jsonParser.write(value);
                }
            }else {
                if(gridRef.current) {
                    gridRef.current.api.showNoRowsOverlay();
                }
                handleErrorResponse(response);
            }
        }catch (error) {
            handleError("An error trying to fetch snapshot " + error);
        }
    }

    const memoizedComponent = useMemo(() => {
        return <TotalMessagesQuotes
                                    discarded={rowInfo ? rowInfo.discarded : 0}
                                    received={rowInfo ? (rowInfo.counter + rowInfo.discarded) : 0}
                                    gridRef={rowInfo ? rowInfo.gridRef : null}
        />;
    }, [displayedRows, rowInfo, filterUpdated]);


    const onFilterChanged = useCallback(() => {
        setFilterUpdated(filterUpdated=>++filterUpdated);
    }, []);

    const memoizedTableComponent = useMemo(() => {
        return <AgGridReact
            defaultColDef={{
                resizable: true,
                sortable: true,
                cellDataType: false
            }}
            gridOptions={{
                suppressColumnVirtualisation: true,
                getRowId: (params) => params.data._rowId.toString(),
                rowHeight: 25,
                asyncTransactionWaitMillis: 100,
                onAsyncTransactionsFlushed: () => {
                    if(gridRef.current) {
                        setDisplayedRows(gridRef.current.api.getDisplayedRowCount());
                    }
                },
                cellSelection:true,
                ensureDomOrder: true
            }}
            onFilterChanged={onFilterChanged}
            onColumnMoved={params => {
                if(params.finished && params.source === "uiColumnMoved") {
                    let newColsDefinitionOrder: any[] = [];
                    params.api.getAllGridColumns()?.forEach((cc: any)=>{
                        newColsDefinitionOrder.push(columnDefs.filter(c=>cc["colId"] === c.field)[0]);
                    });
                    setColumnDefs(newColsDefinitionOrder);
                }
            }}
            columnDefs={[...columnDefs]}
            rowModelType={"clientSide"}
            ref={gridRef}
        >
        </AgGridReact>
    }, [pauseRef, columnDefs]);



    return (fetchingData || sseConnected ) ? (
        <ErrorBoundary fallbackRender={FallbackError}>
            {memoizedComponent}
            <Row className={"table-container"}>
                <Col>
                    <div className={"ag-theme-alpine table-wrapper"}>
                        <div id={"data-table"}>
                            {memoizedTableComponent}
                        </div>
                    </div>
                </Col>
            </Row>
        </ErrorBoundary>
    ) : null;
}

export default AgGridComponent;
