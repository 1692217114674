import React, {useEffect, useState} from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import {useAuth} from "react-oidc-context";
import {NotAuthenticatedUser} from "./login/NotAuthenticatedUser";
import {ErrorBoundary} from "react-error-boundary";
import FallbackError from "./common/FallbackError";
import {ProtectedComponent} from "./login/ProtectedComponent";


function App() {

    const auth = useAuth();
    const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

    useEffect(() => {
        if(!auth.isAuthenticated && !isLoggingOut && !auth.isLoading) {
            localStorage.setItem("FenixaRedirectUrl", window.location.pathname + window.location.search);
            auth.signinRedirect()
                .then(() => {});
        }
    }, [auth.isAuthenticated, auth.isLoading, isLoggingOut, auth]);


    if (auth.isLoading && auth.error?.message) {
        const message = auth.error?.message || 'Loading';
        return <NotAuthenticatedUser message={message}/>;
    }

    if (auth.error) {
        return <NotAuthenticatedUser message={auth.error.message}/>;
    }

    if(auth.isAuthenticated && !auth.activeNavigator){
        return (<ErrorBoundary fallbackRender={FallbackError}>
                    <ProtectedComponent setIsLoggingOut={setIsLoggingOut}/>
                </ErrorBoundary>);
    }

    return auth.isAuthenticated && auth.error ?
        <>
            <NotAuthenticatedUser message={'Unknown state. Please try again later.'}/>;
        </>
        : <></>
}

export default App;
