// src/Callback.tsx
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const Callback  = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const redirectUrl = localStorage.getItem('FenixaRedirectUrl') || '/';
        localStorage.removeItem('FenixaRedirectUrl');
        navigate(redirectUrl);
    }, [navigate]);

    return <div></div>;
};

export default Callback;
