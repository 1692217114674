import React from "react";
import {AgGridReact} from '@ag-grid-community/react';
import {Col, Row} from "react-bootstrap";

import { ModuleRegistry } from '@ag-grid-community/core';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

ModuleRegistry.registerModules([ SetFilterModule ]);

interface BasicTableProps{
    columns: any[];
    data: any[],
    gridOptions: object,
    containerClass: string
}

function BasicTable(props: BasicTableProps) {

    const {columns, data, gridOptions, containerClass} = props;

    return <Row className={containerClass}>
                <Col>
                    <div className={"ag-theme-alpine table-wrapper"}>
                        <div id={"data-table"}>
                            <AgGridReact
                                defaultColDef={{
                                    flex: 1,
                                    resizable: true,
                                    sortable: true,
                                    cellDataType: false
                                }}
                                gridId={"customersTableId"}
                                columnDefs={columns}
                                rowData={data}
                                onGridReady={grid=>{
                                    grid.api.autoSizeAllColumns();
                                }}
                                {...gridOptions}
                            >
                            </AgGridReact>
                        </div>
                    </div>
                </Col>
            </Row>
}

export default BasicTable;