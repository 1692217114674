import React from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import logo from "../../public/fenixamd.png";
import SignInButton from "./SignInButton";

interface LandingProps{
    message?: string
}

export const Landing = (props: LandingProps) => {

    const {
        message
    } = props;

    let errorMessage = null;
    if(message){
        if(message === 'login_required')
            errorMessage = "User logged out. please signin again"
        else if(message === 'Token is not active')
            errorMessage = "Session is not active, please signin again"
        else {
            errorMessage = `An error has occurred with the authentication: ${message}`
        }
    }

    return (
        <Container className={"landing-container"}>
            <Row>
                <Col className={"card-container"}>
                    <div className="centered-card">
                        <Card style={{width: "400px", height: "100%"}}>
                            <Card.Img variant="top" src={logo} style={{padding: "10px"}}/>
                            <Card.Body className="left-aligned-content">
                                <Card.Title style={{textAlign: "center"}}>Fenixa</Card.Title>
                                <Card.Text>
                                    {message ? errorMessage : 'Fenixa serves as your gateway for inspecting and creating Web-API URLs dedicated to Fenics Market Data.'}
                                </Card.Text>
                                <Card.Footer style={{backgroundColor: "transparent", textAlign: "center"}}>
                                    <SignInButton variant={"outline-primary"}/>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>

        </Container>
    )
}