import {useSearchParams} from "react-router-dom";
import {FieldOption, SelectedField} from "./Types";
import {useEffect} from "react";
import {buildCustomerFromURL, compressString, decompressString, getConnectionTypeByValue} from "./Utils";

interface UrlParamsUpdaterProps{
    connectionType: FieldOption | null,
    setConnectionType: Function,
    selectedFields: SelectedField[],
    setSelectedFields: Function,
    conflation: number,
    setConflation: Function,
    snapshotAge: number,
    setSnapshotAge: Function,
    delta: boolean,
    setDelta: Function,
    selectedCustomer: FieldOption | null,
    setSelectedCustomer: Function,
    sseConnected: boolean,
    setSseConnected: Function,
    fetchingData: boolean,
    setFetchingData: Function,
    origin: string | null,
    setOrigin: Function,
    filtersIndex: number | null,
    setFiltersIndex: Function
}

function UrlParamsUpdater(props: UrlParamsUpdaterProps) {


    const [searchParams, setSearchParams] = useSearchParams();
    const {
        connectionType,
        setConnectionType,
        selectedFields,
        setSelectedFields,
        conflation,
        setConflation,
        snapshotAge,
        setSnapshotAge,
        delta,
        setDelta,
        selectedCustomer,
        setSelectedCustomer,
        sseConnected,
        setSseConnected,
        fetchingData,
        setFetchingData,
        origin,
        setOrigin,
        filtersIndex,
        setFiltersIndex
        } = props

    function getParamByName(name: string, defaultValue: number){
        const paramValue: string | null = searchParams.get(name);
        if(paramValue){
            return parseInt(paramValue);
        }
        return defaultValue;
    }

    useEffect(() => {
        let hasUpdates = false;
        if(connectionType && connectionType.value !== searchParams.get("connectionType")){
            searchParams.set("connectionType", connectionType.value);
            hasUpdates = true;
        }
        if(selectedFields && compressString(JSON.stringify(selectedFields)) !== searchParams.get("filters")){
            searchParams.set("filters", compressString(JSON.stringify(selectedFields)));
            hasUpdates = true;
        }
        if(conflation && conflation !== getParamByName("conflation", 0)){
            searchParams.set("conflation", conflation.toString());
            hasUpdates = true;
        }
        if(snapshotAge && snapshotAge !== getParamByName("snapshotAge", 30)){
            searchParams.set("snapshotAge", snapshotAge.toString());
            hasUpdates = true;
        }
        if((delta ? 'yes' : 'no') !== searchParams.get("delta")){
            searchParams.set("delta", delta ? 'yes' : 'no');
            hasUpdates = true;
        }
        if(searchParams.get("customer") && selectedCustomer && selectedCustomer.value !== '-1' && selectedCustomer.value !== buildCustomerFromURL(searchParams.get("customer"))?.value){
            searchParams.set("customer", selectedCustomer ? `${selectedCustomer.label}__${selectedCustomer.value}` : '');
            hasUpdates = true;
        }
        else if(selectedCustomer && selectedCustomer.value === "0" && origin !== 'sub'){
            searchParams.delete("customer");
            searchParams.delete("filterIndex");
            hasUpdates = true;
        }
        if((fetchingData ? 'yes' : 'no') !== searchParams.get("fetchingData")){
            searchParams.set("fetchingData", fetchingData ? 'yes' : 'no');
            hasUpdates = true;
        }
        if((sseConnected ? 'yes' : 'no') !== searchParams.get("sseConnected")){
            searchParams.set("sseConnected", sseConnected ? 'yes' : 'no');
            hasUpdates = true;
        }
        if(origin && origin !== searchParams.get("origin")){
            searchParams.set("origin", origin);
            hasUpdates = true;
        }

        if(filtersIndex != null && filtersIndex >=0 && filtersIndex.toString() !== searchParams.get("filterIndex")){
            searchParams.set("filterIndex", filtersIndex.toString());
            hasUpdates = true;
        }else if(filtersIndex && filtersIndex < 0){
            searchParams.delete("filterIndex");
            hasUpdates = true;
        }
        if(hasUpdates) {
            setSearchParams(searchParams);
        }
    }, [
        connectionType,
        selectedFields,
        conflation,
        snapshotAge,
        delta,
        selectedCustomer,
        fetchingData,
        sseConnected,
        origin,
        filtersIndex
    ]);

    useEffect(() => {
        if(connectionType && searchParams.get("connectionType") && searchParams.get("connectionType") !== connectionType.value){
            setConnectionType(getConnectionTypeByValue(searchParams.get("connectionType")));
        }
        if(selectedFields && searchParams.get("filters") && searchParams.get("filters") !== compressString(JSON.stringify(selectedFields))){
            setSelectedFields(JSON.parse(decompressString(searchParams.get("filters"))));
        }
        if(conflation && searchParams.get("conflation") && searchParams.get("conflation") !== conflation.toString()){
            setConflation(getParamByName("conflation", 0));
        }
        if(snapshotAge && searchParams.get("snapshotAge") && searchParams.get("snapshotAge") !== snapshotAge.toString()){
            setSnapshotAge(getParamByName("snapshotAge", 30));
        }
        if(searchParams.get("delta") && searchParams.get("delta") !== (delta ? 'yes' : 'no')){
            setDelta(searchParams.get('delta') === 'yes');
        }
        if(searchParams.get("customer") && buildCustomerFromURL(searchParams.get("customer"))){
            if(selectedCustomer && selectedCustomer.value !== buildCustomerFromURL(searchParams.get("customer"))?.value) {
                setSelectedCustomer(buildCustomerFromURL(searchParams.get("customer")));
            }
        }
        if(searchParams.get("sseConnected") && searchParams.get("sseConnected") !== (sseConnected ? 'yes' : 'no')){
            setSseConnected(searchParams.get('sseConnected') === 'yes');
        }
        if(searchParams.get("fetchingData") && searchParams.get("fetchingData") !== (fetchingData ? 'yes' : 'no')){
            setFetchingData(searchParams.get('fetchingData') === 'yes');
        }
        if(searchParams.get("origin") && searchParams.get("origin") !== origin){
            setOrigin(searchParams.get('origin'));
        }
        if(searchParams.get("filterIndex") && ((filtersIndex && searchParams.get("filterIndex") !== filtersIndex.toString())
                                                || !filtersIndex)){
            setFiltersIndex(getParamByName("filterIndex", -1));
        }
    }, [searchParams]);

    return null;

}

export default UrlParamsUpdater;
