import React, {useEffect, useRef, useState} from "react";
import {diff as DiffEditor} from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-json";

interface ResponsiveDiffEditorProps{
    value: string[],
    aceProperties: any
}

const ResponsiveDiffEditor = (props: ResponsiveDiffEditorProps) => {
    const {value, aceProperties} = props;
    const editorRef = useRef<DiffEditor | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    // ResizeObserver to monitor container size changes
    useEffect(() => {
        const observer = new ResizeObserver(() => {
            if (containerRef.current) {
                const {clientWidth, clientHeight} = containerRef.current;
                setDimensions({width: clientWidth, height: clientHeight});
            }
        });

        if(containerRef.current){
            observer.observe(containerRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    const editorKey = `${dimensions.width}-${dimensions.height}`;

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
            {dimensions.width > 0 && dimensions.height > 0 &&
                <DiffEditor
                    key={editorKey}
                    ref={editorRef}
                    width={`${dimensions.width}px`}
                    height={`${dimensions.height}px`}
                    value={value}
                    {...aceProperties}
                />
            }
        </div>
    );
};

export default ResponsiveDiffEditor;