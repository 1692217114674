import React from "react";
import {Button} from "react-bootstrap";
import {useAuth} from "react-oidc-context";
import {MdLogin} from "react-icons/md";

interface SignInButtonProps{
    variant: string
}

function SignInButton (props: SignInButtonProps) {
    const { variant } = props;
    const auth = useAuth();

    const handleLogin = () => {
        localStorage.setItem("FenixaRedirectUrl", window.location.pathname + window.location.search);
        auth.signinRedirect()
            .then(()=>{});
    };
    return (
        <Button size={"sm"} variant={variant} style={{marginLeft: "20px"}} onClick={() => handleLogin()}>
            <MdLogin /> Sign in
        </Button>
    );
}

export default SignInButton;