import React from 'react';
import {Alert, Card, Container} from "react-bootstrap";

interface FallbackErrorProps{
    error: any;
}
function FallbackError(props: FallbackErrorProps) {
    const { error } = props;

    return (
        <Container>
            <div>
                <Card style={{ width: '50%', marginLeft: "25%" }}>
                    <Card.Body>
                        <Card.Title>Error Occurred!</Card.Title>
                        <Card.Text>
                            <Alert variant={"danger"}>
                                {error.message}
                            </Alert>
                        </Card.Text>
                        <Card.Link href="/">Go home</Card.Link>
                    </Card.Body>
                </Card>
            </div>
        </Container>
    );
}

export default FallbackError;