
const reportWebVitals = (onPerfEntry?: any) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ onCLS, onINP, onLCP, onTTFB, onFCP, onFID }) => {
        onCLS(onPerfEntry);
        onINP(onPerfEntry);
        onLCP(onPerfEntry);
        onTTFB(onPerfEntry);
        onFCP(onPerfEntry);
        onFID(onPerfEntry);
    });
  }
};

export default reportWebVitals;
