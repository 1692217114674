import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {buildCustomerFromURL, decompressString, getConnectionTypeByValue, getUser} from "./Utils";


interface URLParamsReaderProps{
    setConnectionType: Function,
    setSelectedFields: Function,
    setConflation: Function,
    setSnapshotAge: Function,
    setDelta: Function,
    setSelectedCustomer: Function,
    setSseConnected: Function,
    setFetchingData: Function,
    setOrigin: Function,
    setFiltersIndex: Function
}


const URLParamsReader = (props : URLParamsReaderProps) => {
    const {
        setConnectionType,
        setSelectedFields,
        setConflation,
        setSnapshotAge,
        setDelta,
        setSelectedCustomer,
        setSseConnected,
        setFetchingData,
        setOrigin,
        setFiltersIndex
    } = props;
    const [searchParams] = useSearchParams();

    function getParamByName(name: string, defaultValue: number){
        const paramValue: string | null = searchParams.get(name);
        if(paramValue){
            return parseInt(paramValue);
        }
        return defaultValue;
    }

    useEffect(() => {
        if(searchParams.get("connectionType")){
            setConnectionType(getConnectionTypeByValue(searchParams.get('connectionType')));
        }else{
            setConnectionType(getConnectionTypeByValue('snapshot'));
        }
        if(searchParams.get("filters")){
            setSelectedFields(JSON.parse(decompressString(searchParams.get("filters"))));
        }
        if(searchParams.get("conflation")){
            setConflation(getParamByName('conflation', 0))
        }
        if(searchParams.get("snapshotAge")){
            setSnapshotAge(getParamByName('snapshotAge', 30))
        }
        if(searchParams.get("delta")){
            setDelta(searchParams.get('delta') ? searchParams.get('delta') === 'yes' : true);
        }
        if(searchParams.get("customer")){
            setSelectedCustomer(buildCustomerFromURL(searchParams.get("customer")));
        }else{
            setSelectedCustomer({
                label: getUser()?.profile.name,
                value: "0"
            });
        }
        if(searchParams.get("sseConnected")){
            setSseConnected(searchParams.get('sseConnected') ? searchParams.get('sseConnected') === 'yes' : false);
        }
        if(searchParams.get("fetchingData")){
            setFetchingData(searchParams.get('fetchingData') ? searchParams.get('fetchingData') === 'yes' : false);
        }
        if(searchParams.get("origin")){
            setOrigin(searchParams.get('origin') ? searchParams.get('origin') : '');
        }
        if(searchParams.get("filterIndex")){
            setFiltersIndex(getParamByName("filterIndex", -1));
        }

    }, []);

    // This component returns nothing, so it's invisible
    return null;
};

export default URLParamsReader;