import React, {useEffect, useState} from 'react';
import {Button, Col, FormControl, InputGroup, Row} from "react-bootstrap";
import {FieldOption} from "./Types";
import {buildURL, getUser} from "./Utils";
import {useLocation, useSearchParams} from "react-router-dom";
import {ErrorBoundary} from "react-error-boundary";
import FallbackError from "../common/FallbackError";

interface CustomerURLValidationProps{
    permissions: any;
    selectedCustomer: string | undefined,
    apiFields: FieldOption[],
    connectionType: FieldOption,
    delta: boolean,
    snapshotAge: number,
    conflation: number,
    updateFieldsFromURL: Function,
    sseConnected: Boolean,
    fetchingData: Boolean
}




function CustomerURLValidation(props: CustomerURLValidationProps) {

    const { permissions,
            selectedCustomer,
            connectionType,
            delta,
            snapshotAge,
            conflation,
            updateFieldsFromURL,
            sseConnected,
            fetchingData
    } = props;

    const [url, setUrl] = useState<string>('');
    const [urlFromFields, setUrlFromFields] = useState<string>('');
    const [title, setTitle] = useState<string>('');

    const { search } = useLocation();
    const [searchParams] = useSearchParams();
    const [origin, setOrigin] = useState<string>('');
    const [customerName, setCustomerName] = useState<string>('');
    const [urlReadonly, setUrlReadOnly] = useState<boolean>(false);



    useEffect(() => {
        if(permissions && "filter" in permissions) {
            setUrl(buildURL(permissions, connectionType, snapshotAge, delta, conflation));
            setUrlFromFields(buildURL(permissions, connectionType, snapshotAge, delta, conflation));
        }
    }, [permissions, connectionType, snapshotAge, delta, conflation]);

    useEffect(()=>{
        const user = getUser();
        const customerURL = searchParams.get("customer");
        const loggedInUserOid = user?.profile.sub;
        if (customerURL && customerURL.split("__")[1] !== loggedInUserOid) {
            setCustomerName(customerURL.split("__")[0]);
        }else{
            setCustomerName('');
        }
        const originURL = searchParams.get('origin') != null ? searchParams.get('origin') : '';
        if (originURL !== origin) {
            setOrigin(originURL != null ? originURL : '');
        }
    }, [search]);

    useEffect(() => {
        if(!sseConnected && !fetchingData){
            setTitle('URL Converter');
            setUrlReadOnly(false);
        }else{
            const obo = origin === 'sub' && customerName !== '' ? `on Behalf of ${customerName}` : '';
            setTitle(`${(fetchingData ? 'Snapshot' : 'Subscription')} ${obo}`);
            setUrlReadOnly(true);
        }
    }, [sseConnected, fetchingData, origin, customerName]);


    return Array.isArray(permissions) ?
            <ErrorBoundary fallbackRender={FallbackError}>
                <div style={{marginTop: "2px"}}>
                    <Row>
                        <Col>
                            <fieldset>
                                <legend>{title}</legend>
                                <InputGroup className="mb-1">
                                    <InputGroup.Text id="basic-addon3">
                                        URL
                                    </InputGroup.Text>
                                    <FormControl id="basic-url" aria-describedby="basic-addon3"
                                                 placeholder={`Please paste here the URL to validate against user ${selectedCustomer} permissions`}
                                                 value={url}
                                                 onChange={(event)=>{
                                                     setUrl(event.target.value);
                                                 }}
                                                 readOnly={urlReadonly}
                                    />
                                    {
                                        url !== urlFromFields &&
                                        <Button variant="outline-success" onClick={()=>{
                                            updateFieldsFromURL(url);
                                        }}>
                                            Load fields from URL
                                        </Button>
                                    }
                                </InputGroup>
                            </fieldset>
                        </Col>
                    </Row>
                </div>
            </ErrorBoundary>
            : <></>

}

export default CustomerURLValidation;
