import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    addLoading,
    buildHeadersFromUser,
    compressString,
    getAPIServer,
    handleErrorResponse,
    handleNetworkError, hasRole, removeLoading
} from "./Utils";
import BasicTable from "./BasicTable";
import {AgGridReact} from '@ag-grid-community/react';
import {ModuleRegistry, RowClassRules} from "@ag-grid-community/core";
import {Roles} from "./Types";
import {useNavigate} from "react-router";
import {MdOutlineDisplaySettings} from "react-icons/md";
import {RangeSelectionModule} from '@ag-grid-enterprise/range-selection';
import {ClipboardModule} from "@ag-grid-enterprise/clipboard";
import {RiSpyFill} from "react-icons/ri";
import {Badge} from "react-bootstrap";
import FallbackError from "../common/FallbackError";
import {ErrorBoundary} from "react-error-boundary";

ModuleRegistry.registerModules([ RangeSelectionModule, ClipboardModule ]);


interface SubscriptionsProps{
    role: string[] | undefined
}

function buildSubscriptionsData(clients: any[]) {
    let data: any[] = [];
    clients.forEach((c: any)=>{
        let out = {
            ...c,
            state: 0
        };
        if(c.request) {
            out["request"] = JSON.parse(c.request);
        }
        if(out["endTime"]){
            let startRow = {...out};
            startRow["entryType"] = "endEntry";
            data.push(startRow);
        }else{
            out["state"] = 2;
        }
        out["entryType"] = "startEntry";
        data.push(out);
    });
    return data;
}


const RequestCellRenderer = (props: any) =>{
    let request = props.value === '' ? [] : JSON.parse(props.value);
    return <div style={{wordWrap: "break-word", overflowWrap: "break-word"}}>
            {Object.keys(request).filter((k: string)=>!k.startsWith("_")).map((k: string, index: number) => {
            return <div key={`key${index}`} style={{width: "100%", position: "relative", display: "flex"}}>
                    <span key={`div_${index}_${k}`} style={{display: "inline-block", width: "100px"}}><b>{k}</b></span>
                    {request[k].map(((t: string, indexT: number) => {
                            const clazzName = k.endsWith("_r") ? "regexp-cell-wrapper" : "single-cell-wrapper";
                            return <span key={`val_${k}_${t}_${indexT}`} className={clazzName}>{t}<br/></span>
                        }
                    ))}
                   </div>
            })}
        </div>
}

const RunRequestCellRenderer = (props: any) =>{
    const navigate = useNavigate();

    function handleClickRun(){
        let selectedFields: any[] = [];
        let processedFields: string[] = [];
        Object.keys(props.data.request).forEach(k=>{
            let fieldName = k;
            if(k.endsWith("_r")){
                fieldName = k.replace("_r", "");
            }
            if(!processedFields.includes(fieldName) && !fieldName.startsWith("_")) {
                let fieldFilter = {
                    "field": fieldName,
                    "filters": {
                        "match": props.data.request[`${fieldName}`] ? props.data.request[`${fieldName}`] : [],
                        "regExp": props.data.request[`${fieldName}_r`] ? props.data.request[`${fieldName}_r`] : []
                    }
                };
                selectedFields.push(fieldFilter);
                processedFields.push(fieldName);
            }
        });
        const connectionType = props.data.type;
        let url = `/?filters=${compressString(JSON.stringify(selectedFields))}&connectionType=${connectionType}`;
        if(props.data.request["_snapshot_age"]){
            url += `&snapshotAge=${props.data.request["_snapshot_age"]}`;
        }
        if(props.data.request["_conflation"]){
            url += `&conflation=${props.data.request["_conflation"]}`;
        }
        if("_delta" in props.data.request){
            url += `&delta=${props.data.request['_delta']}`;
        }
        const autoConnectParam = connectionType === "snapshot" ? "fetchingData" : "sseConnected";
        url += `&${autoConnectParam}=no`;
        url += `&customer=${props.data.name}__${props.data.oid}&origin=sub`;
        navigate(url);
    }

    return <div className={"text-center"}>
               <MdOutlineDisplaySettings className={"icon-button"} onClick={()=>handleClickRun()}/>
          </div>
}

const NameCellRenderer = (props: any) =>{
    const oboAdmin = props.data.admin ?
        <Badge bg={"primary"}>
            <RiSpyFill/> {props.data.admin}
        </Badge>
        : null;
    return <div>
                <span style={{width: "100%"}}>{props.data.name}</span>
                <br/>
                {oboAdmin}
            </div>
}


function Subscriptions(props: SubscriptionsProps) {

    const { role } = props;
    const [subscriptions, setSubscriptions] = useState<any[]>([]);
    const [subscriptionsData, setSubscriptionsData] = useState<any[]>([]);
    const [colDefs, setColDefs] = useState<any[]>([]);
    const gridRef = useRef<AgGridReact>(null);


    useEffect(() => {
        addLoading();
        const requestHeaders = buildHeadersFromUser();
        fetch(`${getAPIServer()}/fenixa/subscriptions`, {
            method: 'GET',
            headers: requestHeaders,
        })
            .then(response => {
                if(response.ok) {
                    return response.json();
                }else{
                    handleErrorResponse(response);
                }
            })
            .then(data => {
                if(data) {
                    setSubscriptions(data);
                }
            })
            .finally(()=> {
                removeLoading();
            })
            .catch((error)=>{
                handleNetworkError(error);
            });
    }, []);

    // @ts-ignore
    const requestDateComparator = (valueA: string, valueB: string, nodeA: any, nodeB: any) => {
        if(nodeA && nodeB) {
            const dateToCompareA = nodeA.data.entryType === "startEntry" ? nodeA.data.startTime : nodeA.data.endTime;
            const dateToCompareB = nodeB.data.entryType === "startEntry" ? nodeB.data.startTime : nodeB.data.endTime;
            if (new Date(dateToCompareA) > new Date(dateToCompareB)) {
                return 1;
            } else if (new Date(dateToCompareA) < new Date(dateToCompareB)) {
                return -1;
            }
            return 0;
        }
        return 0;
    };

    function buildColDefsFields() {
        let colDefs: any[] = [
            {
                field: "state",
                hide: true,
                lockVisible: true
            },
            {
                field: "id",
                hide: true,
                lockVisible: true
            },
            {
                field: "startTime",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                comparator: requestDateComparator,
                flex: 0.8
            },
            {
                field: "endTime",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                comparator: requestDateComparator,
                flex: 0.8
            },
            {
                field: "count",
                headerName: "Instrument count",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                flex: 0.6
            },
            {
                field: "type",
                filter: "agSetColumnFilter",
                floatingFilter: true,
                filterParams: {
                    defaultToNothingSelected: true,
                },
                flex: 0.5
            },
            {
                field: "request",
                cellRenderer: RequestCellRenderer,
                filter: 'agTextColumnFilter',
                valueGetter: (params: any) => {
                    return params.data.request == null ? '' : JSON.stringify(params.data.request);
                },
                wrapText: true,
                autoHeight: true,
                floatingFilter: true,
                flex: 2
            },
            {
                headerName: "Inspect",
                cellRenderer: RunRequestCellRenderer,
                hide: false,
                lockVisible: true,
                sortable: false,
                flex: 0.4
            },
            {
                field: "host",
                headerName: "Host",
                filter: "agSetColumnFilter",
                filterParams: {
                    defaultToNothingSelected: true,
                },
                floatingFilter: true
            }
        ];
        if(hasRole(role, Roles.ADMIN)){
            colDefs.unshift(
                {
                    field: "name",
                    cellRenderer: NameCellRenderer,
                    filter: "agSetColumnFilter",
                    filterParams: {
                        defaultToNothingSelected: true,
                    },
                    floatingFilter: true
                },
            );
        }
        return colDefs;
    }

    useEffect(() => {
        if(subscriptions && subscriptions.length > 0){
            setSubscriptionsData(buildSubscriptionsData(subscriptions));
            setColDefs(buildColDefsFields());
        }
    }, [subscriptions]);


    const onSelectionChanged = useCallback((params: any) => {
        const subId = params.api.getSelectedRows()[0]["id"];
        gridRef.current!.api.forEachNode(function (rowNode) {
            if(rowNode.data.state !== 2) {
                rowNode.setDataValue('state', rowNode.data.id === subId ? 1 : 0);
            }
        });
    }, []);


    const rowClassRules = useMemo<RowClassRules>(() => {
        return {
            'subscription-row': (params) => {
                return params.data.state === 0;
            },
            'subscription-pair': (params) => {
                return params.data.state === 1;
            },
            'running-subscription': (params) => {
                return params.data.state === 2;
            }
        };
    }, []);

    const applyDefaultSort = useCallback(() => {
        gridRef.current!.api.applyColumnState({
            state: [{ colId: "startTime", sort: "desc" }],
            defaultState: { sort: null },
        });
    }, []);

    const onFirstDataRendered = useCallback(() => {
        applyDefaultSort();
    }, []);


    const gridOptions = {
        onSelectionChanged: onSelectionChanged,
        ref: gridRef,
        rowClassRules: rowClassRules,
        onFirstDataRendered: onFirstDataRendered,
        comparator: {requestDateComparator},
        ensureDomOrder: true,
        rowSelection: {
            mode: "singleRow",
            checkboxes: false,
            enableClickSelection: true
        }
    }

    return <ErrorBoundary fallbackRender={FallbackError}>
                    <fieldset>
                        <BasicTable columns={colDefs}
                                    data={subscriptionsData}
                                    gridOptions={gridOptions}
                                    containerClass={"basic-table-container"}
                        />
                   </fieldset>
            </ErrorBoundary>
}

export default Subscriptions;
