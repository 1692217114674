import React from 'react';
import {Alert} from "react-bootstrap";
import {AlertInfo} from "../components/Types";

interface AlertComponentProps{
    alertInfo: AlertInfo;
    handleClose: Function;
}


function AlertComponent(props: AlertComponentProps) {


    const { alertInfo, handleClose } = props;

    return alertInfo.show ? (
        <Alert variant={alertInfo.variant} onClose={() => handleClose()} dismissible>
            {alertInfo.title && alertInfo.title !== '' && <Alert.Heading>{alertInfo.title}</Alert.Heading>}
            <p>
                {alertInfo.message}
            </p>
        </Alert>
    ) : null;

}

export default AlertComponent;
