import {Button} from "react-bootstrap";
import {BsMicrosoftTeams} from "react-icons/bs";
import React from "react";

interface TeamsLinkUserMessageProps{
    user: string;
    email: string,
    topicChat: string,
    message: string
}

function TeamsLinkUserMessage (props: TeamsLinkUserMessageProps) {
    const {
        user,
        email,
        topicChat,
        message
    } = props;

    const url = `https://teams.microsoft.com/l/chat/0/0?users=${email}&topicName=${topicChat}&message=${message}`;

    return (
        <a target={"_blank"} href={url} style={{marginRight: "10px"}}>
            <Button size={"sm"} variant={"link"}> <BsMicrosoftTeams /> {user}</Button>
        </a>
    )

}

export default TeamsLinkUserMessage;