import {Col, Row} from "react-bootstrap";
import React from "react";
import {AgGridReact} from "@ag-grid-community/react";


interface TotalMessagesQuotesProps{
    received: number,
    discarded: number,
    gridRef: AgGridReact | null
}

function TotalMessagesQuotes(props: TotalMessagesQuotesProps){

    const { received, discarded, gridRef } = props;

    let counterType = 'Displayed';
    let displayed = 0;
    if(gridRef) {
        const model: any = gridRef.api.getFilterModel();
        counterType = Object.keys(model).length > 0 ? 'Filtered' : 'Displayed';
        displayed = gridRef.api.getDisplayedRowCount();
    }

    return (
        <Row>
            <Col>
                <div style={{width: "100%"}}>
                    <blockquote className="blockquote float-sm-start" style={{marginTop: "20px", fontSize: "medium"}}>
                        <footer className="blockquote-footer">
                            <cite title="Source Title">{counterType} Messages: {displayed}</cite>
                            <cite title="Source Title">, Received: {received}</cite>
                            {discarded > 0 && <cite title="Discarded">, Discarded: {discarded}</cite>}
                        </footer>
                    </blockquote>
                </div>
            </Col>
        </Row>
    )
}

export default TotalMessagesQuotes;