import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Card, Col, FormLabel, Row} from "react-bootstrap";
import makeAnimated from 'react-select/animated';
import {FieldOption, SelectedField, ValidateField} from "./Types";
import {MultiValue} from "react-select/dist/declarations/src/types";
import Select, {components, DropdownIndicatorProps} from "react-select";
import CreatableSelect from "react-select/creatable";
import {AiOutlineClose} from "react-icons/ai";
import {
    buildHeadersFromUser,
    checkOnBehalfOfUser,
    compareOption,
    getAPIServer,
    getAsyncFields,
    handleErrorResponse,
    handleNetworkError,
    isValidRegExp,
    loadOptionsByField,
    updateOptionsByField
} from "./Utils";
import chroma from "chroma-js";
import {FaCheck, FaRegCopy} from "react-icons/fa";
import {useLocation, useSearchParams} from "react-router-dom";
import {debounce} from "lodash"
import loadingGif from "../../public/loading-dots.gif";
import FallbackError from "../common/FallbackError";
import {ErrorBoundary} from "react-error-boundary";

interface AttributeFilterProps{
    field: string;
    selectedFields: SelectedField[],
    updateSelectedFields: Function,
    latestSelectedField: SelectedField | null,
    removeAttribute: Function,
    isValidOption: Function,
    eventCounter: number
}

const MAX_UI_ITEMS = 5000;


function findSelectedValuesByTypeAndField(selectedFields: SelectedField[], field: string, type: string){
    if(selectedFields && selectedFields.filter((f: SelectedField) => f.field === field).length === 1){
        const fieldFilters = selectedFields.filter((f: SelectedField) => f.field === field)[0];
        if(type === "match") {
            return [...fieldFilters.filters.match];
        }else {
            return [...fieldFilters.filters.regExp];
        }
    }
    return [];
}

function AttributeFilter(props: AttributeFilterProps) {

    const { field,
        selectedFields,
        latestSelectedField,
        updateSelectedFields,
        removeAttribute,
        isValidOption,
        eventCounter
    } = props;
    const [attributeOptions, setAttributeOptions] = useState<FieldOption[] | null>(null);
    const [selectedExactMatch, setSelectedExactMatch] = useState<string[]>([]);
    const [selectedRegExpMatch, setSelectedRegExpMatch] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [asyncSelect, setAsyncSelect] = useState<boolean>(getAsyncFields().includes(field));
    const [inputValue, setInputValue] = useState<string>('');
    const [totalOptions, setTotalOptions] = useState<number>(-1);
    const [selectedValues, setSelectedValues] = useState<FieldOption[]>([]);
    const [regExpValues, setRegExpValues] = useState<FieldOption[]>([]);
    const [regExpOptions, setRegExpOptions] = useState<FieldOption[]>(loadOptionsByField(field));
    const [styles, setStyles] = useState({});
    const { search } = useLocation();
    const [searchParams] = useSearchParams();
    const [origin, setOrigin] = useState<string | null>('');
    const [customerOID, setCustomerOID] = useState<string>('');
    const [searchInput, setSearchInput] = useState<string>('');
    const [validateField, setValidateField] = useState<ValidateField>({
        style: {},
        event: eventCounter
    });

    const animatedComponents = makeAnimated();

    const getValuesControllerRef = useRef<AbortController | null>(null);
    const getTotalsControllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        setSelectedExactMatch(findSelectedValuesByTypeAndField(selectedFields, field, "match"));
        setSelectedRegExpMatch(findSelectedValuesByTypeAndField(selectedFields, field, "regExp"));

        return ()=>{
            if(getValuesControllerRef.current instanceof AbortController){
                getValuesControllerRef.current.abort();
            }
            if(getTotalsControllerRef.current instanceof AbortController){
                getTotalsControllerRef.current.abort();
            }
        }
    }, [selectedFields, field]);

    useEffect(()=>{
        const customerURL = searchParams.get("customer");
        if(customerURL){
            setCustomerOID(customerURL.split("__")[1]);
        }else{
            setCustomerOID("--");
        }
        if(searchParams.get('origin')){
            setOrigin(searchParams.get('origin') ? searchParams.get('origin') : '');
        }else{
            setOrigin('--');
        }
    }, [search, searchParams]);



    useEffect(() => {
        if(attributeOptions && attributeOptions.length === MAX_UI_ITEMS){
            if(!isMyOwnField(field, latestSelectedField) || totalOptions < 0) {
                if(getTotalsControllerRef.current instanceof AbortController){
                    getTotalsControllerRef.current.abort();
                }
                getTotalsControllerRef.current = new AbortController();
                setTotalOptions(-1);
                const requestHeaders = buildHeadersFromUser();
                checkOnBehalfOfUser(requestHeaders, origin, customerOID);
                const fetchOptions: RequestInit = {
                    method: "POST",
                    headers: requestHeaders,
                    body: JSON.stringify(selectedFields)
                }
                if(getTotalsControllerRef.current instanceof AbortController){
                    fetchOptions.signal = getTotalsControllerRef.current.signal
                }
                fetch(`${getAPIServer()}/fenixa/field/${field}/total`, fetchOptions)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleErrorResponse(response);
                            setTotalOptions(0);
                        }
                    })
                    .then(data => {
                        if (data) {
                            setTotalOptions(data["total"]);
                            if (data["total"] < MAX_UI_ITEMS && asyncSelect) {
                                setAsyncSelect(false);
                            } else if (data["total"] > MAX_UI_ITEMS && !asyncSelect) {
                                setAsyncSelect(true);
                            }
                        }
                    })
                    .catch((error)=>{
                        if(error.name !== 'AbortError') {
                            handleNetworkError(error);
                        }
                    });
            }
        }else {
            if(attributeOptions) {
                setTotalOptions(attributeOptions.length);
            }
        }
    }, [selectedFields, attributeOptions, asyncSelect]);


    const updateSelectedValues = useCallback(() =>{
        if(selectedFields.filter(sf => sf.field === field).length === 1){
            setSelectedValues(selectedFields.filter(sf => sf.field === field)[0]
                .filters
                .match.map(v => {
                    return {
                        value: v,
                        label: v
                    }
                }));
        }
    }, [selectedFields, field]);

    function isMyOwnField(field: string, latestField: SelectedField | null){
        if(latestField){
            return latestField.field === field;
        }
        return false;
    }





    useEffect(() => {
        if(customerOID !== '' && origin !== '') {
            if (selectedFields.filter(sf => sf.field === field).length === 1) {
                setRegExpValues(selectedFields.filter(sf => sf.field === field)[0]
                    .filters
                    .regExp.map(v => {
                        return {
                            value: v,
                            label: v
                        }
                    }));
            }

            if(!isMyOwnField(field, latestSelectedField) || !attributeOptions) {
                if(getValuesControllerRef.current instanceof AbortController){
                    getValuesControllerRef.current.abort();
                }
                getValuesControllerRef.current = new AbortController();
                updateSelectedValues();
                setTotalOptions(-1);
                setLoading(true);
                const requestHeaders = buildHeadersFromUser();
                checkOnBehalfOfUser(requestHeaders, origin, customerOID);
                const fetchOptions: RequestInit = {
                    method: "POST",
                    headers: requestHeaders,
                    body: JSON.stringify([...selectedFields])
                }

                if(getValuesControllerRef.current instanceof AbortController){
                    fetchOptions.signal = getValuesControllerRef.current.signal;
                }
                fetch(`${getAPIServer()}/fenixa/field/${field}/values`, fetchOptions)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleErrorResponse(response);
                        }
                    })
                    .then((data: any) => {
                        if (data) {
                            loadOptions(data["data"]);
                            updateSelectedValues();
                            if(data["data"].length === MAX_UI_ITEMS) {
                                setAsyncSelect(true);
                                setTotalOptions(-1);
                            }
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    })
                    .catch((error)=>{
                        if(error.name !== 'AbortError') {
                            handleNetworkError(error);
                        }
                    });

            }else{
                updateSelectedValues();
            }
        }
        //@ts-ignore
    }, [selectedFields, field, origin, customerOID]);

    useEffect(() => {
        if(attributeOptions && attributeOptions.length > 0 && selectedExactMatch && selectedExactMatch.length > 0){
            if(!asyncSelect) {
                setStyles({
                    multiValue: (provided: any, props: any) => {
                        let settings = {
                            ...provided
                        }
                        if (!isOptionAvailable(props.data.value)) {
                            const color = chroma("red");
                            settings["backgroundColor"] = color.alpha(0.4).css();
                        }
                        return settings;
                    }
                });
            }else{
                const requestHeaders = buildHeadersFromUser();
                checkOnBehalfOfUser(requestHeaders, origin, customerOID);
                fetch(`${getAPIServer()}/fenixa/field/${field}/validate?options=${selectedExactMatch}`, {
                    method: "POST",
                    headers: requestHeaders,
                    body: JSON.stringify(selectedFields)
                })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleErrorResponse(response);
                        }
                    })
                    .then(data => {
                        if(data) {
                            setStyles({
                                multiValue: (provided: any, props: any) => {
                                    let settings = {
                                        ...provided
                                    }
                                    if (!data["data"].includes(props.data.value)) {
                                        const color = chroma("red");
                                        settings["backgroundColor"] = color.alpha(0.4).css();
                                    }
                                    return settings;
                                }
                            });
                        }
                    })
                    .catch((error)=>{
                        handleNetworkError(error);
                    });
            }
        }
    }, [attributeOptions, selectedExactMatch, origin, customerOID, asyncSelect, selectedFields]);


    function loadOptions(options: string[]){
        if(options) {
            setAttributeOptions(
                options
                    .map((d: string) => {
                        return {
                            label: d,
                            value: d
                        }
                    })
            );
        }
    }


    useEffect(() => {
        if(searchInput !== '') {
            setAttributeOptions([]);
            const requestHeaders = buildHeadersFromUser();
            checkOnBehalfOfUser(requestHeaders, origin, customerOID);
            if(searchInput.length >= 2) {
                setLoading(true);
                fetch(`${getAPIServer()}/fenixa/field/${field}/values?filter=${searchInput}`, {
                    method: "POST",
                    headers: requestHeaders,
                    body: JSON.stringify(selectedFields)
                })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleErrorResponse(response);
                        }
                    })
                    .then(data => {
                        if(data) {
                            setAttributeOptions(
                                data["data"].map((d: string) => {
                                    return {
                                        label: d,
                                        value: d
                                    }
                                })
                            );
                        }
                    })
                    .finally(()=>{
                        setLoading(false);
                    })
                    .catch((error)=>{
                        handleNetworkError(error);
                    })
            }
        }
    }, [field, searchInput, selectedFields]);

    useEffect(() => {
        const filtersByField: SelectedField[] = selectedFields.filter(s=>s.field === field);
        if(eventCounter > validateField.event && filtersByField.length === 1) {
            if(filtersByField[0]["filters"]["match"].length === 0 && filtersByField[0]["filters"]["regExp"].length === 0){
                setValidateField({
                    style: {
                        border: "1px solid #dc3545"
                    },
                    event: eventCounter
                });
            }else{
                setValidateField({style: {}, event: eventCounter});
            }
        }else{
            setValidateField({style: {}, event: eventCounter});
        }
    }, [eventCounter, selectedFields]);

    const debouncedSearch = React.useRef(
        debounce((searchInput: string) => {
            // setAttributeOptions(await promiseOptions(searchInput));
            setSearchInput(searchInput);
        }, 1000)
    ).current;

    let selectOptions: any = {
        placeholder: "Select one or more values",
        isDisabled: loading
    }


    function isOptionAvailable(value: string){
        return attributeOptions && attributeOptions.map(o=>o.value).includes(value);
    }

    const CustomMultiValueRemove = (props: any) => {

        const [color, setColor] = useState("black");

        return (
            <>
                <div className={"css-12a83d4-MultiValueRemove css-12a83d4-MultiValueCopy copy-selected-option-button"}>
                    {color === "black" &&
                        <FaRegCopy className={"copy-selected-option-button"}
                                   onClick={()=>{
                                       navigator.clipboard
                                           .writeText(props.data.label)
                                           .then(()=>{});
                                       setColor("green");
                                       setTimeout(()=>{
                                           setColor("black");
                                       }, 3000);
                                   }}
                                   style={{height: "12px", marginBottom: "0px", color: color}}/>
                    }
                    {color === "green" &&
                        <FaCheck className={"copy-selected-option-button"}
                                 onClick={()=>{
                                     navigator.clipboard
                                         .writeText(props.data.label)
                                         .then(()=>{});
                                     setColor("green");
                                     setTimeout(()=>{
                                         setColor("black");
                                     }, 3000);
                                 }}
                                 style={{height: "12px", marginBottom: "0px", color: color}}/>
                    }
                </div>
                <components.MultiValueRemove {...props} />
            </>
        );
    };

    const DropdownIndicatorExactMatch = (
        props: DropdownIndicatorProps<FieldOption, true>
    ) => {

        return (
            <components.DropdownIndicator {...props}/>
        );
    };

    const DropdownIndicatorRegExp = (
        props: DropdownIndicatorProps<FieldOption, true>
    ) => {

        return (
            <components.DropdownIndicator {...props} />
        );
    };


    const totalItemsComponent = totalOptions < 0 ?
        <blockquote className="blockquote float-sm-end blockquote-card-loading">
            <footer>
                <img src={loadingGif} alt={"loading"} style={{opacity: 0.6}}></img>
            </footer>
        </blockquote>
        :
        <blockquote className="blockquote float-sm-end blockquote-card">
            <footer className="blockquote-footer">
                {
                    <cite title="Source Title" style={{marginLeft: "3px"}}>Total options {totalOptions}</cite>
                }
            </footer>
        </blockquote>;


    return (
        <ErrorBoundary fallbackRender={FallbackError}>
            <div key={field} style={{marginTop: "2px", marginBottom: "5px"}}>
                <Row>
                    <Col>
                        <Card style={validateField.style}>
                            <Card.Header className={"field-card-header"}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5>{field}</h5>
                                    <div onClick={()=>{
                                            removeAttribute(field);
                                        }}
                                        style={{cursor: "pointer"}}>
                                        <AiOutlineClose/>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body className={"field-card-body"}>
                                <Row>
                                    <Col>
                                        <FormLabel style={{width: "100%", marginBottom: 0}}>Exact match
                                            <div>
                                            {
                                                asyncSelect ?
                                                    <Select
                                                        inputValue={inputValue}
                                                        isMulti={true}
                                                        onInputChange={(newValue: string)=>{
                                                            setInputValue(newValue);
                                                            debouncedSearch(newValue);
                                                        }}
                                                        components={{...animatedComponents,
                                                            MultiValueRemove: CustomMultiValueRemove,
                                                            DropdownIndicator: DropdownIndicatorRegExp
                                                        }}
                                                        options={attributeOptions ? attributeOptions.sort(compareOption) : []}
                                                        onChange={(newValue: MultiValue<FieldOption>)=> {
                                                            const attributeValues = newValue.map(n => n["value"]);
                                                            if(newValue && isValidOption(newValue, field, "match")) {
                                                                updateSelectedFields({
                                                                    field: field,
                                                                    filters: {
                                                                        match: attributeValues,
                                                                        regExp: [...selectedRegExpMatch]
                                                                    }
                                                                });
                                                                setSelectedExactMatch([...attributeValues]);
                                                            }
                                                        }}
                                                        styles={styles}
                                                        isLoading={loading}
                                                        value={selectedValues}
                                                        placeholder="Search for an option..."
                                                        noOptionsMessage={()=> inputValue === '' ? "Type what are you looking for" : "No results"}
                                                        classNamePrefix={"attribute-filter-select"}
                                                    />
                                                    :
                                                    <Select options={attributeOptions ? attributeOptions.sort(compareOption) : []}
                                                            className={"exact-match"}
                                                            isMulti
                                                            components={{...animatedComponents,
                                                                MultiValueRemove: CustomMultiValueRemove,
                                                                DropdownIndicator: DropdownIndicatorExactMatch
                                                            }}
                                                            value={selectedValues}
                                                            onChange={(newValue: MultiValue<FieldOption>)=>{
                                                                const attributeValues = newValue.map(n => n["value"]);
                                                                if(newValue && isValidOption(newValue, field, "match")) {
                                                                    updateSelectedFields({
                                                                        field: field,
                                                                        filters: {
                                                                            match: attributeValues,
                                                                            regExp: [...selectedRegExpMatch]
                                                                        }
                                                                    });
                                                                    setSelectedExactMatch([...attributeValues]);
                                                                }
                                                            }}
                                                            isLoading={loading}
                                                            name={field}
                                                            styles={styles}
                                                            {...selectOptions}
                                                    />
                                            }
                                            {totalItemsComponent}
                                            </div>
                                            {
                                                Object.keys(validateField.style).length > 0 &&
                                                <div className={"invalid-filter"}>Please provide at least one {field}</div>
                                            }
                                        </FormLabel>
                                    </Col>
                                    <Col>
                                        <FormLabel style={{width: "100%"}}>Regular Expressions
                                            <div>
                                                <CreatableSelect
                                                    options={regExpOptions}
                                                    className={"regexp-match"}
                                                    value={regExpValues}
                                                    isMulti
                                                    components={{...animatedComponents,
                                                        MultiValueRemove: CustomMultiValueRemove,
                                                        DropdownIndicator: DropdownIndicatorRegExp
                                                    }}
                                                    onChange={(newValue: MultiValue<FieldOption>)=>{
                                                        const attributeValues = newValue.map(n => n["value"]);
                                                        if(newValue && isValidOption(newValue, field, "regExp")) {
                                                            updateSelectedFields({
                                                                field: field,
                                                                filters: {
                                                                    match: [...selectedExactMatch],
                                                                    regExp: attributeValues
                                                                }
                                                            });
                                                            setSelectedRegExpMatch([...attributeValues]);
                                                        }
                                                    }}
                                                    isValidNewOption={(inputValue)=>{
                                                        return isValidRegExp(inputValue);
                                                    }}
                                                    onCreateOption={(inputValue)=>{
                                                        updateOptionsByField(field, inputValue);
                                                        setRegExpOptions(loadOptionsByField(field));
                                                        let selectedRegExpValues = regExpValues.map(r=>r.value);
                                                        selectedRegExpValues.push(inputValue);
                                                        updateSelectedFields({
                                                            field: field,
                                                            filters: {
                                                                match: [...selectedExactMatch],
                                                                regExp: selectedRegExpValues
                                                            }
                                                        });
                                                        setSelectedRegExpMatch([...selectedRegExpValues]);
                                                    }}
                                                    name={field}
                                                    placeholder={"Type your regular expression"}
                                                />
                                            </div>
                                        </FormLabel>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </ErrorBoundary>
    );
}

export default AttributeFilter;
