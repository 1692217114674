import {FieldOption} from "./Types";
import {Button} from "react-bootstrap";
import {BiStop} from "react-icons/bi";
import {GoDesktopDownload} from "react-icons/go";
import AgGridComponent from "./AgGridComponent";
import React, {useEffect, useRef, useState} from "react";
import {BsPauseFill, BsPlayFill} from "react-icons/bs";


interface DataFeedComponentProps{
    sseConnected: boolean;
    fetchingData: boolean,
    pullData: Function,
    disconnectSSE: Function,
    setFetchingData: Function,
    connectionType: FieldOption,
    buildURL: Function
}


function DataFeedComponent(props: DataFeedComponentProps){
    const { sseConnected,
        fetchingData,
        pullData,
        disconnectSSE,
        setFetchingData,
        connectionType,
        buildURL
    } = props;

    const [pause, setPause] = useState<boolean>(false);
    const pauseRef = useRef<boolean>(false);
    const [snapshotDataComplete, setSnapshotDataComplete] = useState<boolean>(false);
    const snapshotDataCompleteRef = useRef(false);

    pauseRef.current = pause;
    snapshotDataCompleteRef.current = snapshotDataComplete;

    useEffect(()=>{
        if(!fetchingData){
            setSnapshotDataComplete(false);
        }
    }, [fetchingData]);

    useEffect(()=>{
    }, [pause]);

    return <>
            <div className={"float-md-end"} style={{marginBottom: "5px", display: "inline", marginLeft: "auto"}}>
                <Button hidden={!pauseRef.current  || (!fetchingData && !sseConnected)} variant={"outline-primary"}
                        style={{marginLeft: "auto", marginRight: "5px"}} size={"sm"}
                        onClick={()=>setPause(false)}>
                    <BsPlayFill/> Resume
                </Button>
                <Button hidden={pauseRef.current || (fetchingData && snapshotDataCompleteRef.current) || (!fetchingData && !sseConnected)}
                        variant={"outline-primary"}
                        style={{marginLeft: "auto", marginRight: "5px"}}
                        onClick={()=>{
                            setPause(true);
                        }}
                        size={"sm"}>
                    <BsPauseFill/> Pause
                </Button>
                <Button size={"sm"} style={{marginLeft: "auto", marginRight: "5px"}} variant={"outline-primary"} hidden={!sseConnected} onClick={()=>disconnectSSE()}>
                    <BiStop/> Disconnect
                </Button>
                <Button size={"sm"} style={{marginLeft: "auto", marginRight: "5px"}} variant={"outline-primary"} hidden={!fetchingData || sseConnected} onClick={()=>{
                    setFetchingData(false);
                    setSnapshotDataComplete(true);
                }}>
                    <BiStop/> Back to Filters
                </Button>
                <Button size={"sm"} variant={"primary"} onClick={()=> pullData()} style={{marginLeft: "auto"}}  hidden={sseConnected || fetchingData}>
                    <GoDesktopDownload style={{marginRight: "5px"}}/>
                    {connectionType.value === 'subscribe' ? 'Subscribe' : 'Fetch Snapshot'}
                </Button>
            </div>
            <AgGridComponent buildURL={buildURL}
                             fetchingData={fetchingData}
                             sseConnected={sseConnected}
                             setPause={setPause}
                             pauseRef={pauseRef}
                             setSnapshotDataComplete={setSnapshotDataComplete}
            ></AgGridComponent>
        </>

}

export default DataFeedComponent;