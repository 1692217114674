import React, {useEffect, useState} from 'react';
import {Card, CardGroup, CloseButton, Col, Row} from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import FallbackError from "../common/FallbackError";
import {ErrorBoundary} from "react-error-boundary";


interface CustomerPermissionsCarouselProps{
    permissions: any;
    loadCustomerPermissions: Function;
    removeCustomerPermissions: Function;
    cancelEdit: Function;
    selectedFiltersSet?: number | null;
    readonly: boolean;
    permissionsEverything: boolean;
}


//Note: if change this value use it too at App.css .permission-card
const FILTERS_BY_SLIDE = 3;

function CustomerPermissionsCarousel(props: CustomerPermissionsCarouselProps) {


    const {
        permissions,
        loadCustomerPermissions,
        removeCustomerPermissions,
        selectedFiltersSet,
        cancelEdit,
        readonly,
        permissionsEverything
    } = props;

    const [isRemoveAction, setIsRemoveAction] = useState<boolean>(false);
    const [cardItems, setCardItems] = useState<any>(null);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if(permissions) {
            setCardItems(permissions);
            setIndex(0);
        }
        return ()=> {
            setCardItems(null);
        }
    }, [permissions]);


    function buildItems(permissions: any) {
        if(permissions && permissions.length > 0){
            const chunks = chunk(permissions, FILTERS_BY_SLIDE);
            return chunks.map((c: any, index: number)=>{
                return buildCarouselItem(c, index);
            })
        }
        return null;
    }


    function buildCardItemByPermissions(filtersSet: any, indexP: number, index: number){
        const isSelected = selectedFiltersSet != null && selectedFiltersSet >= 0 && selectedFiltersSet === indexP * FILTERS_BY_SLIDE + index && !isRemoveAction;
        let title = `Filters Set ${indexP * FILTERS_BY_SLIDE + index + 1} of ${permissions.length}`;
        let cardStyle: any = {
            cursor: "pointer"
        };
        if(isSelected){
            cardStyle["backgroundColor"] = "#005B96";
            cardStyle["color"] = "#FFFFFF";
        }

        return <Card className={"permission-card"} key={`${indexP}_${index}`}>
                    <Card.Header
                        onClick={()=>{
                            if(!readonly) {
                                setIsRemoveAction(false);
                                if (!isSelected) {
                                    loadCustomerPermissions(indexP * FILTERS_BY_SLIDE + index);
                                } else {
                                    cancelEdit();
                                }
                            }
                        }}
                        style={cardStyle}>
                        {title}
                    </Card.Header>
                    <Card.Body>
                        {!readonly &&
                            <CloseButton className={`remove-card-icon ${isSelected ? 'btn-close-white' : ''}`}
                                         onClick={(event: any)=>{
                                            setIsRemoveAction(true);
                                            event.stopPropagation();
                                            removeCustomerPermissions(indexP * FILTERS_BY_SLIDE + index);
                                         }}
                            />
                        }
                        {
                                filtersSet.map((r: any, indexF: number)=> {
                                    const field = r["field"];
                                    return <Row key={`${indexP}_${index}_${indexF}`} style={{"marginBottom": "3px"}}>
                                        <Col sm={3}>
                                            <code style={{color: "#005B96", fontFamily: "inherit", fontSize: "inherit"}}>
                                                {field}
                                            </code>
                                        </Col>
                                        <Col sm={9}>
                                            {
                                                r["filters"]["match"].map((f: string, indexB: number) =>
                                                    <pre key={`badge_${indexB}`} style={{fontFamily: "inherit", fontSize: "inherit", marginBottom: "0.2rem"}}>{f}</pre>
                                                )
                                            }
                                            {
                                                r["filters"]["regExp"].map((f: string, indexB: number) =>{
                                                    return <div key={`badge_reg_exp_${indexB}`}  style={{marginBottom: "1px"}}>
                                                                <pre className={"text-wrap"}
                                                                     style={{color: "#CC8400", fontFamily: "inherit", fontSize: "inherit", marginBottom: "0.2rem"}}>
                                                                        {f}
                                                                </pre>
                                                            </div>

                                                })
                                            }
                                        </Col>
                                    </Row>
                                })
                        }
                    </Card.Body>
                </Card>
    }

    function buildCarouselItem(permissions: any, index: number){
        return <Carousel.Item key={`item_${index}`}>
                    <CardGroup>
                        {
                            permissions.map((p: any, indexP: number)=>{
                                return buildCardItemByPermissions(p, index, indexP)
                            })
                        }
                    </CardGroup>
                </Carousel.Item>
    }

    function chunk(arr: any, size: number) {
        return Array.from({length: Math.ceil(arr.length / size)}, (_v: any, i: number) =>
            arr.slice(i * size, i * size + size)
        );
    }

    const items = buildItems(permissions);

    const showSlides = permissions.length > FILTERS_BY_SLIDE;

    const message = permissionsEverything ? 'Permissioned for everything' : 'No permissions defined';

    return cardItems && cardItems.length > 0 ? (
        <ErrorBoundary fallbackRender={FallbackError}>
            <Carousel variant="dark"
                      controls={showSlides}
                      indicators={showSlides}
                      interval={null}
                      activeIndex={index}
                      onSelect={(selectedIndex: number)=>{
                          setIndex(selectedIndex);
                      }}
            >
                {items}
            </Carousel>
        </ErrorBoundary>
    ) : (
        <Row>
            <Col>{message}</Col>
        </Row>
    );

}

export default CustomerPermissionsCarousel;
