import {
    connectionTypes,
    CustomerNote,
    FieldOption,
    ResourceAccess,
    Roles,
    RolesDescription,
    SelectedField
} from "./Types";
import lz from "lz-string";
import {toast} from "react-toastify";
import {User} from "oidc-client-ts";


const FENIXA = "FENIXA";
export const FENICS_MD_SYSADMIN = "FenicsMDSysAdmin";


export function compare( a: SelectedField, b: SelectedField ) {
    if ( a.field < b.field ){
        return -1;
    }
    if ( a.field > b.field ){
        return 1;
    }
    return 0;
}

export function compareOption( a: FieldOption, b: FieldOption ) {
    if ( a.value < b.value ){
        return -1;
    }
    if ( a.value > b.value ){
        return 1;
    }
    return 0;
}

export function compareNotes( a: CustomerNote, b: CustomerNote ) {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    if ( dateA < dateB ){
        return 1;
    }
    if ( dateB < dateA ){
        return -1;
    }
    return 0;
}

export function dateParser( date: Date, options: any ) {
    return date.toLocaleString('en-US', options);
}



export function getServerURL(){
    let webServiceAPI = '';
    try {
        // @ts-ignore
        webServiceAPI = process.env.FMD_WEB_SERVICE_API;
    }catch(error){
        //@ts-ignore
        webServiceAPI = document.getElementById("idAppBody").getAttribute("data-web-service-api");
    }
    return webServiceAPI;
}

export function getEnvironment(){
    let environment = '';
    try {
        // @ts-ignore
        environment = process.env.FMD_WEB_API_DEPLOYMENT_ENV;
    }catch(error){
        //@ts-ignore
        environment = document.getElementById("idAppBody").getAttribute("data-env");
    }
    return environment;
}

export function getNodeEnvironment(){
    let nodeEnv = '';
    try {
        // @ts-ignore
        nodeEnv = process.env.NODE_ENV;
    }catch(error){
        //@ts-ignore
        nodeEnv = document.getElementById("idAppBody").getAttribute("data-node-env");
    }
    return nodeEnv;
}

export function getAPIServer(){
    let apiServerHost = '';
    try {
        // @ts-ignore
        const environment = getNodeEnvironment();
        if(environment === "development"){
            return "";
        }else {
            return getServerURL();
        }
    }catch(error){
        console.warn("Not able to find api server ", error);
    }
    return apiServerHost;
}

export function getVersion(){
    let version = '';
    try {
        // @ts-ignore
        version = process.env.FMD_WEB_API_RELEASE_VERSION;
    }catch(error){
        //@ts-ignore
        version = document.getElementById("idAppBody").getAttribute("data-version");
    }
    return version;
}

export function getConnectionTypeByValue(value: string | null){
    return connectionTypes.filter(c => c.value === value)[0];
}

export function isValidRegExp(value: string) {
    let isValid = true;
    try {
        if(value !== '') {
            new RegExp(value);
        }else{
            isValid = false;
        }
    }catch(e){
        isValid = false;
    }
    return isValid;
}


export function getClientId(){
    let clientId = '';
    try {
        // @ts-ignore
        clientId = process.env.CLIENT_ID;
    }catch(error){
        //@ts-ignore
        clientId = document.getElementById("idAppBody").getAttribute("data-client-id");
    }
    return clientId;
}



export function buildHeadersFromToken(token: string){
    let requestHeaders: HeadersInit = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-cache'
    }
    return requestHeaders;
}

export function buildHeadersFromUser(){
    const user = getUser();
    let requestHeaders: HeadersInit = {
        "Authorization": `Bearer ${user?.id_token}`,
        "Content-Type": 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
    return requestHeaders;
}

export function buildURL(permissions: any,
                         connectionType: FieldOption | null,
                         snapshotAge: number,
                         delta: boolean,
                         conflation: number){
    if(connectionType) {
        let server = getAPIServer();
        let url = `${server}/webapi/${connectionType.value}?`;
        if (permissions) {
            url += permissions
                .filter((sf: any) => sf.filters.match.length > 0 || sf.filters.regExp.length > 0)
                .map((sf: any) => {
                    return sf.filters.match.map((m: string) => `${sf.field}=${m}`)
                        .concat(sf.filters.regExp.map((m: string) => `${sf.field}_r=${m}`))
                        .join("&")
                }).join("&");
        }
        if (connectionType.value === 'subscribe') {
            if (conflation && conflation > 0) {
                url += "&_conflation=" + conflation;
            }
            if (!delta) {
                url += "&_delta=" + (delta ? "true" : "false");
            }
            if (snapshotAge && snapshotAge >= 0) {
                url += "&_snapshot_age=" + snapshotAge;
            }
        }
        if (connectionType.value === 'snapshot') {
            if (snapshotAge && snapshotAge !== 30) {
                url += "&_snapshot_age=" + snapshotAge;
            }
        }
        return encodeURI(url);
    }
    return "";
}

export function getAuthority(){
    let tenant = '';
    try {
        // @ts-ignore
        tenant = process.env.AUTHORITY;
    }catch(error){
        //@ts-ignore
        tenant = document.getElementById("idAppBody").getAttribute("data-authority");
    }
    return tenant;
}

export function determineRole(rol: string) {
    if (rol === Roles.ADMIN) {
        return Roles.ADMIN;
    } else if (rol === Roles.USER){
        return Roles.USER;
    } else if (rol === Roles.CUSTOMER){
        return Roles.CUSTOMER;
    } else if (rol === Roles.SYS_ADMIN){
        return Roles.SYS_ADMIN;
    }
    return Roles.NONE;
}




export function compressString(data: string){
    return lz.compressToBase64(data)
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=/g, '');
}

export function decompressString(data: string | null){
    if(data) {
        return lz.decompressFromBase64(data
            .replace(/-/g, '+')
            .replace(/_/g, '/'));
    }
    return "";
}

export function loadOptionsByField(field: string){
    let fenixaConf = window.localStorage.getItem(FENIXA);
    let configuration;
    if (fenixaConf && fenixaConf !== '') {
        configuration = JSON.parse(fenixaConf);
        if(field in configuration){
            return configuration[field].map((f: string)=>{
                return {
                    label: f,
                    value: f
                }
            });
        }
    }
    return [];
}

export function updateOptionsByField(field: string, value: string){
    let fenixaConf = window.localStorage.getItem(FENIXA);
    let configuration: any = {};
    let options : string[] = [];
    if (fenixaConf && fenixaConf !== '') {
        configuration = JSON.parse(fenixaConf);
        if(field in configuration){
            options = configuration[field];
        }
    }
    if(!options.includes(value)){
        options.push(value);
    }
    configuration[field] = options;
    window.localStorage.setItem(FENIXA, JSON.stringify(configuration));
}

export function getMultipleFieldsLoadByUUID(uuid: string){
    let fenixaConf = window.localStorage.getItem(FENIXA);
    let configuration: any = {};
    if (fenixaConf && fenixaConf !== '') {
        configuration = JSON.parse(fenixaConf);
        return configuration[uuid];
    }
}

export function buildCustomerFromURL(customerId: string | null){
    return customerId ? {
        label: customerId.split("__")[0],
        value: customerId.split("__")[1]
    } :
    null
}


export function checkOnBehalfOfUser(requestHeaders: any, origin: string | null, oid: string | null) {
    const currentUser = getUser();
    const currentUserOid = currentUser?.profile.sub;
    if(currentUserOid !== oid && origin && origin === 'sub' && oid && oid !== '0'){
        requestHeaders["__obo__oid__"] = oid;
    }
}

export function checkOnBehalfOfUserToObject(requestHeaders: any, origin: string | null, oid: string | null, currentUser: any) {
    const currentUserOid = currentUser.profile.sub;
    if(currentUserOid !== oid && origin && origin === 'sub' && oid && oid !== '0'){
        requestHeaders["__obo__oid__"] = oid;
    }
}

export function handleError(message: string){
    toast.error(message, {
        className: 'toast-error-message',
        autoClose: false,
        draggable: false,
        closeOnClick: false
    });
}

export function handleNetworkError(error: any){
    if (error instanceof TypeError && error.message.includes('Failed to fetch')) {
        handleError('Network error: Connection refused or server is not reachable.');
    } else {
        handleError('An error occurred:' + error.message);
    }
}

export function handleErrorResponse(response: Response){
    const contentType = response.headers.get('content-type');
    if(contentType && contentType === 'application/json'){
        response.json().then(data =>{
            handleError("detail" in data ? data.detail : data.message);
        })
    }else if (contentType && contentType === 'text/plain'){
        response.text().then(message =>{
            handleError(message);
        })
    }else{
        let message = response.statusText;
        if(message === '' && response.type === 'cors'){
            if(response.status === 405){
                message = 'Method not allowed'
            }
            else if(response.status === 403){
                message = 'Forbidden';
            }
        }
        handleError(message);
    }
}


export function getAsyncFields(){
    return ["sym", "altSym"];
}

export function getUser() {
    const oidcStorage = localStorage.getItem(`oidc.user:${getAuthority()}:${getClientId()}`)
    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}

export function getUserRole(): string[] | undefined {
    try {
        const user = getUser();
        if (user && user.profile?.resource_access) {
            const roles = (user.profile?.resource_access as ResourceAccess)[getClientId()].roles;
            if (roles.length === 0) {
                return undefined;
            } else if (roles.length > 0) {
                return roles.map(r=>determineRole(r));
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }catch (e){
        return undefined;
    }
}

export function addLoading(){
    let loading = document.getElementById('idLoadingDiv');
    let loadingText = document.getElementById("idLoadingText");
    if(loadingText){
        loadingText.style["display"] = "block";
    }
    if(loading) {
        loading.style["visibility"] = "visible";
    }
    document.body.style.overflow = "hidden";
    document.body.style.height = "100%";
}

export function removeLoading(){
    let loading = document.getElementById('idLoadingDiv');
    let loadingText = document.getElementById("idLoadingText");
    if(loadingText){
        loadingText.style["display"] = "none";
    }
    if(loading) {
        loading.style["visibility"] = "hidden";
    }
    document.body.style.overflow = "auto";
    document.body.style.height = "auto";
}

export function getFenixaAdmins(){
    return [
        {email: "Harish.madhavan@bgcg.com", display: "Harish Madhavan"},
        {email: "ruben.alfarodiaz@bgcg.com", display: "Ruben Alfaro"}
    ]
}

export function isAdmin(roles: string[] | undefined){
    if(roles){
        return roles.includes(Roles.ADMIN);
    }
    return false;
}

export function isCustomer(roles: string[] | undefined){
    if(roles){
        return roles.includes(Roles.CUSTOMER);
    }
    return false;
}

export function hasRole(roles: string[] | undefined, role: string){
    if(roles){
        return roles.includes(role);
    }
    return false;
}

export function getRoleName(key: string) : string | undefined{
    return RolesDescription[key as keyof typeof RolesDescription];
}